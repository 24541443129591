import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { CreditScoreSearchCriteriaInput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { creditScoresCrossTenantApi } from "api/BudgeApi";
import CreditScoresTable from "components/Table/CreditScoresTable";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import ExportCreditScoresButton from "features/credit-scores/ExportCreditScoresButton";
import useTableSorting from "hooks/useTableSorting";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { getCreditScoreFiltersConfig } from "components/Table/CreditScoresTable/filters";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";

const EmployerCreditScoreListPersistSortId = "employer-credit-score-list-sort";

const CreditScoresListTab = () => {
  const filtersConfig = useMemo(() => getCreditScoreFiltersConfig("employer-credit-score-list-filters"), []);
  const { ListFiltersReducer } = useMemo(() => initListFilters(filtersConfig), [filtersConfig]);

  const {
    state: { employer },
  } = useEmployerContext();
  const { sort, sortRefreshIndex, updateSort } = useTableSorting(EmployerCreditScoreListPersistSortId);
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      creditScoresCrossTenantApi
        .searchEmployerCreditScores(
          employer?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString(),
          sort?.columnId,
          sort?.direction
        )
        .then(({ data }) => ({ results: data })),
    [sortRefreshIndex, JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters), sortRefreshIndex]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<CreditScoreSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [JSON.stringify(filters), dispatch]
  );

  return (
    <>
      <ListFiltersContext.Provider value={memoedFilterContextValues}>
        <ListFiltersBar
          filtersConfig={filtersConfig}
          extra={
            <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsFinancials]}>
              <ExportCreditScoresButton employerId={employer?.id!} />
            </ExportAccessScopeValidator>
          }
        />
        <CreditScoresTable
          sort={sort}
          onSort={updateSort}
          requestState={requestState}
          onEndReached={onEndReached}
          keyExtractor={item => item.id}
          employerId={employer?.id}
        />
      </ListFiltersContext.Provider>
    </>
  );
};

export default CreditScoresListTab;
