import { Collapse, TCollapseProps } from "@budgeinc/budge-ui-core";
import React from "react";

const FilterCollapse = ({ children, ...props }: TCollapseProps) => (
  <Collapse
    triggerProps={{
      sx: {
        height: 48,
        ...props.triggerProps?.sx,
      },
      ...props.triggerProps,
    }}
    contentProps={{
      sx: {
        paddingVertical: 16,
        ...props.contentProps?.sx,
      },
      ...props.contentProps,
    }}
    bordered={props.bordered || true}
    {...props}
  >
    {children}
  </Collapse>
);

export default FilterCollapse;
