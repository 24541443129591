import Unauthorized from "components/Unauthorized";
import { TAccessOperation, TScopeRule, useAccessScopeValidator } from "@budgeinc/budge-ui-core";

const withAccessScopeValidator = (Component: any, rule: TScopeRule) => (props: any) => {
  const { validateAccessScopes } = useAccessScopeValidator();

  if (!validateAccessScopes(TAccessOperation.READ, rule)) {
    return <Unauthorized />;
  }

  return <Component {...props} />;
};

export default withAccessScopeValidator;
