import TransactionsTable from "components/Table/TransactionsTable";
import { TransactionInfiniteScrollTableContext } from "components/Table/TransactionsTable/context";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { programsCrossTenantApi } from "api/BudgeApi";
import { ProgramTransactionAdminOutputWithId } from "components/Table/TransactionsTable/types";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { InfiniteScrollDataTableContextType } from "components/Table/tableContext";
import { getTransactionsFiltersConfig } from "components/Table/TransactionsTable/filters";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import { ProgramTransactionSearchCriteriaInput } from "@budgeinc/budge-api";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";

const filtersConfig = getTransactionsFiltersConfig("employee-transactions-list-filters", {
  showSearchBar: false,
});
const { ListFiltersReducer } = initListFilters(filtersConfig);

const TransactionListTab = () => {
  const { state: pageState, employerId } = useEmployeeEntityContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      programsCrossTenantApi
        .searchEmployeeProgramTransactions(
          employerId!,
          pageState.employee?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({
          results: data.map(record => ({
            id: record.programTransaction.id,
            ...record,
          })),
        })),
    [JSON.stringify(filters)]
  );

  const { requestState, updateData, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  const updateRecord = useCallback(
    (newRecord: ProgramTransactionAdminOutputWithId) => {
      updateData(requestState.data.map(record => (record.id === newRecord.id ? newRecord : record)));
    },
    [requestState]
  );

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<ProgramTransactionSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  const memoedContext = useMemo<InfiniteScrollDataTableContextType<ProgramTransactionAdminOutputWithId>>(
    () => ({
      updateRecord,
    }),
    [updateRecord, updateData]
  );

  return (
    <ListFiltersContext.Provider value={memoedFilterContextValues}>
      <TransactionInfiniteScrollTableContext.Provider value={memoedContext}>
        <ListFiltersBar filtersConfig={filtersConfig} />
        <TransactionsTable
          requestState={requestState}
          onEndReached={onEndReached}
          keyExtractor={item => item.programTransaction.id}
          onTransactionUpdated={t => {
            updateRecord({
              ...t,
              id: t.programTransaction.id,
            });
          }}
          showEmployee
        />
      </TransactionInfiniteScrollTableContext.Provider>
    </ListFiltersContext.Provider>
  );
};

export default TransactionListTab;
