import { ACCOUNT_TAB_KEYS } from "pages/AccountEntity";
import { DIRECT_PAYMENT_TAB_KEYS } from "pages/DirectPaymentEntity";
import { EMPLOYEE_TAB_KEYS } from "pages/Employees/EmployeeEntity";
import { EMPLOYER_TAB_KEYS } from "pages/Employers/EmployerEntity";
import { PROGRAM_TAB_KEYS } from "pages/ProgramEntity";
import { AUTH_PROFILE_TAB_KEYS } from "pages/Security/AuthProfileEntity";

export const REDIRECT_PARAM_KEY = "redirect";

const FINANCIAL_PROFILE_BASE_ROUTE = "/financial-profiles";

const MARKETING_BASE_ROUTE = "/marketing";

const RESET_PASSWORD_BASE_ROUTE = "/reset-password";
export const RESET_PASSWORD_SUB_ROUTES = {
  emailSent: "/email-sent",
  expiredLink: "/link-expired",
  success: "/success",
  token: "/:token",
};

export const ROUTES = {
  root: "/",
  reporting: "/reporting",
  metricsKpis: "/metrics-kpis",
  debtPlanEnrollments: "/debt-plan-enrollments",
  settings: "/settings",
  advanced: "/advanced",
  globalLogins: "/global-logins",
  advisoryNotices: "/advisory-notices",
  transactions: "/transactions",
  pageNotFound: "/page-not-found",
  diag: "/diag",
  signin: "/signin",

  // EXPORT
  exports: "/exports",

  // SECURITY
  security: "/security",
  authProfilesEntity: "/security/auth-profiles/:authProfileId",

  // EMPLOYER
  employersList: "/employers",
  employerEntity: "/employers/:employerId",
  employerProgramEntity: "/employers/:employerId/programs/:programId",
  employerEmployeeEntity: "/employers/:employerId/employees/:employeeId",
  employerEmployeeDirectPaymentEntity: "/employers/:employerId/employees/:employeeId/direct-payments/:directPaymentId",
  employerEmployeeAccountEntity: "/employers/:employerId/employees/:employeeId/accounts/:accountId",
  employerEmployeeDebtPlanEntity: "/employers/:employerId/employees/:employeeId/debt-plans/:debtPlanId",
  employerEmployeeOnePayPlanEntity: "/employers/:employerId/employees/:employeeId/onepay-plans/:onePayPlanId",

  // MARKETING
  marketing: MARKETING_BASE_ROUTE,

  // FINANCIAL PROFILES
  financialProfileList: `${FINANCIAL_PROFILE_BASE_ROUTE}`,
  financialProfileEntity: `${FINANCIAL_PROFILE_BASE_ROUTE}/:financialProfileId`,

  // RESET PASSWORD
  resetPassword: RESET_PASSWORD_BASE_ROUTE,
  resetPasswordEmailSent: `${RESET_PASSWORD_BASE_ROUTE}${RESET_PASSWORD_SUB_ROUTES.emailSent}`,
  resetPasswordSuccess: `${RESET_PASSWORD_BASE_ROUTE}${RESET_PASSWORD_SUB_ROUTES.success}`,
  resetPasswordLinkExpired: `${RESET_PASSWORD_BASE_ROUTE}${RESET_PASSWORD_SUB_ROUTES.expiredLink}`,
  changePassword: `${RESET_PASSWORD_BASE_ROUTE}${RESET_PASSWORD_SUB_ROUTES.token}`,
};

const routeWithEntityIds = ({
  route,
  id,
  programId,
  accountId,
  employerId,
  employeeId,
  directPaymentId,
  authProfileId,
  debtPlanId,
  onePayPlanId,
  tab,
}: {
  route: string;
  id?: string;
  programId?: string;
  accountId?: string;
  employerId?: string;
  employeeId?: string;
  directPaymentId?: string;
  authProfileId?: string;
  debtPlanId?: string;
  onePayPlanId?: string;
  tab?: any;
}) =>
  route
    .replace(":directPaymentId", directPaymentId ?? "")
    .replace(":programId", programId ?? "")
    .replace(":accountId", accountId ?? "")
    .replace(":employerId", employerId ?? "")
    .replace(":employeeId", employeeId ?? "")
    .replace(":debtPlanId", debtPlanId ?? "")
    .replace(":onePayPlanId", onePayPlanId ?? "")
    .replace(":authProfileId", authProfileId ?? "")
    .replace(":id", id ?? "") + (tab || "");

export const employerEntityRoute = (employerId: string, tab?: EMPLOYER_TAB_KEYS) =>
  routeWithEntityIds({
    route: ROUTES.employerEntity,
    employerId,
    tab,
  });

export const authProfileEntityRoute = (authProfileId: string, tab?: AUTH_PROFILE_TAB_KEYS) =>
  routeWithEntityIds({
    route: ROUTES.authProfilesEntity,
    authProfileId,
    tab,
  });

export const employeeDirectPaymentEntityRoute = (
  employerId: string,
  employeeId: string,
  directPaymentId: string,
  tab?: DIRECT_PAYMENT_TAB_KEYS
) =>
  routeWithEntityIds({
    route: ROUTES.employerEmployeeDirectPaymentEntity,
    employeeId,
    employerId,
    directPaymentId,
    tab,
  });

export const employeeEntityRoute = (employerId: string, employeeId: string, tab?: EMPLOYEE_TAB_KEYS) =>
  routeWithEntityIds({
    route: ROUTES.employerEmployeeEntity,
    employeeId,
    employerId,
    tab,
  });

export const accountEntityRoute = (employerId: string, employeeId: string, accountId: string, tab?: ACCOUNT_TAB_KEYS) =>
  routeWithEntityIds({
    route: ROUTES.employerEmployeeAccountEntity,
    accountId,
    employerId,
    employeeId,
    tab,
  });

export const debtPlanEntityRoute = (employerId: string, employeeId: string, debtPlanId: string, tab?: any) =>
  routeWithEntityIds({
    route: ROUTES.employerEmployeeDebtPlanEntity,
    debtPlanId,
    employeeId,
    employerId,
    tab,
  });

export const onePayPlanEntityRoute = (employerId: string, employeeId: string, onePayPlanId: string, tab?: any) =>
  routeWithEntityIds({
    route: ROUTES.employerEmployeeOnePayPlanEntity,
    onePayPlanId,
    employeeId,
    employerId,
    tab,
  });

export const programEntityRoute = (employerId: string, programId: string, tab?: PROGRAM_TAB_KEYS) =>
  routeWithEntityIds({
    route: ROUTES.employerProgramEntity,
    programId,
    employerId,
    tab,
  });
