import { AddressInput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { BuildValidationSchema } from "@budgeinc/budge-ui-utils";
import {
  addressFieldsValidations,
  AddressSectionForm,
  getAddressFieldsInitialValues,
  ADDRESS_FIELDS,
  Text,
  EditSaveToggleButtons,
  AccessScopeValidator,
  Stack,
  TAccessOperation,
} from "@budgeinc/budge-ui-core";
import { FormikConsumer, FormikContextType } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { TEntityCollapseEditForm } from "./CollapseEditForm";

const validationSchema = BuildValidationSchema({
  ...addressFieldsValidations,
});

interface OwnProps {
  initialOpen?: boolean;
  headerExtra?: ReactNode;
  validateOnLoad?: boolean;
  CollapseEditFormComponent: (props: TEntityCollapseEditForm<any, any>) => JSX.Element;
}

const AddressInfoCard = ({ CollapseEditFormComponent, initialOpen, headerExtra, validateOnLoad = false }: OwnProps) => {
  const [editModeOn, setEditMode] = useState(false);

  return (
    <CollapseEditFormComponent
      header={
        <Stack.Horizontal spacing="md" alignItems="center">
          <Text fw="500" variant="bodyMedium">
            Address
          </Text>
          {headerExtra}
        </Stack.Horizontal>
      }
      getFormValues={entity => ({
        ...getAddressFieldsInitialValues(entity.address),
      })}
      initialOpen={initialOpen}
      setEditMode={setEditMode}
      validationSchema={validationSchema}
      transformForSubmit={(values: AddressInput) => {
        const address: AddressInput = {
          line1: values.line1,
          city: values.city,
          state: values.state,
          zip: values.zip,
        };

        if (values.line2) {
          address.line2 = values.line2;
        }

        return { address };
      }}
    >
      <FormikConsumer>
        {form => (
          <AddressInfoFormContent
            form={form}
            validateOnLoad={validateOnLoad}
            editModeOn={editModeOn}
            setEditMode={setEditMode}
            isLoading={form.isSubmitting}
          />
        )}
      </FormikConsumer>
    </CollapseEditFormComponent>
  );
};

const AddressInfoFormContent = ({
  form,
  editModeOn,
  isLoading,
  validateOnLoad,
  setEditMode,
}: {
  form: FormikContextType<any>;
  isLoading: boolean;
  editModeOn: boolean;
  validateOnLoad: boolean;
  setEditMode: (value: boolean) => void;
}) => {
  useEffect(() => {
    if (validateOnLoad) {
      const touchedFields: Record<any, boolean> = {};
      Object.values(ADDRESS_FIELDS).forEach(field => {
        touchedFields[field] = true;
      });
      form.setTouched(touchedFields, true);
    }
  }, []);

  return (
    <Stack spacing="md">
      <AddressSectionForm disabled={!editModeOn} />
      <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.Clients]}>
        <EditSaveToggleButtons
          isEditModeEnabled={editModeOn}
          setEditMode={setEditMode}
          onSubmit={form.handleSubmit}
          saveBtnDisabled={!form.dirty || !!Object.values(form.errors).length}
          onCancel={() => form.setValues(form.initialValues, true)}
          loading={isLoading}
        />
      </AccessScopeValidator>
    </Stack>
  );
};

export default AddressInfoCard;
