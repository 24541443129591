import { ExportRequestOutput } from "@budgeinc/budge-api";
import { Pressable, Text, toast } from "@budgeinc/budge-ui-core";
import { Link } from "components/Link";
import { formatEnumValue } from "utils/format";
import { EXPORTS_TAB_KEYS } from "./types";

export const showExportRequestToast = (request: ExportRequestOutput) =>
  toast.success({
    title: `${formatEnumValue(request.type)} export requested`,
    message: close => (
      <Pressable onPress={close}>
        <Link
          to={`/exports?search=${request.id}${
            request.isScheduled ? EXPORTS_TAB_KEYS.SCHEDULED_EXPORTS : EXPORTS_TAB_KEYS.RESULTS
          }`}
        >
          <Text color="white" tdl="underline">
            View Export
          </Text>
        </Link>
      </Pressable>
    ),
    duration: 10 * 1000,
  });
