export const budgeEnv = process.env.REACT_APP_BUDGE_ENV;

export const isProd = process.env.REACT_APP_BUDGE_ENV === "production";
export const isTest = process.env.REACT_APP_BUDGE_ENV === "test";
export const isDev = process.env.REACT_APP_BUDGE_ENV === "development";
export const isDemo = process.env.REACT_APP_BUDGE_ENV === "demo";

export const appWebVersion = process.env.REACT_APP_BUDGE_VERSION_WEB;

export const appVersion = appWebVersion?.substring(appWebVersion?.indexOf("v"), appWebVersion?.indexOf("("));
