type TBaseFilterConfig<TSearchCriteria> = {
  title: string;
  apiAttribute: keyof TSearchCriteria;
  defaultOpen?: boolean;
};

export type TCheckboxGroupFilterConfig<TSearchCriteria> = TBaseFilterConfig<TSearchCriteria> & {
  type: "checkboxgroup";
  options: {
    label: string;
    value: any;
  }[];
};

export type TBooleanFilterConfig<TSearchCriteria> = TBaseFilterConfig<TSearchCriteria> & {
  type: "boolean";
  labelTrue?: string;
  labelFalse?: string;
};

export type TStringFilterConfig<TSearchCriteria> = TBaseFilterConfig<TSearchCriteria> & {
  type: "string";
  label: string;
};

export type TRangeFilterConfig<TSearchCriteria> = Omit<TBaseFilterConfig<TSearchCriteria>, "apiAttribute"> & {
  type: "range";
  subType: "number" | "date";
  labelFrom?: string;
  labelTo?: string;
  apiAttributeFrom: keyof TSearchCriteria;
  apiAttributeTo: keyof TSearchCriteria;
};

export type TFilter<TSearchCriteria> =
  | TCheckboxGroupFilterConfig<TSearchCriteria>
  | TBooleanFilterConfig<TSearchCriteria>
  | TStringFilterConfig<TSearchCriteria>
  | TRangeFilterConfig<TSearchCriteria>;

export type TFiltersConfig<TSearchCriteria> = {
  persistId: string;
  filters: TFilter<TSearchCriteria>[];
  showAdvancedFilters?: boolean;
} & (
  | {
      showSearchBar: true;
      searchBarPlaceholder?: string;
    }
  | {
      showSearchBar?: false;
      searchBarPlaceholder?: never;
    }
);

export enum BooleanFilterValues {
  YES = "yes",
  NO = "no",
}
