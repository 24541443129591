import { queries } from "api/queries";
import { TFetchExportRequestFileInput } from "api/queries/backoffice";
import { useBudgeQuery } from "api/useBudgeQuery";

export const useFetchExportRequestFile = (input: TFetchExportRequestFileInput, enabled: boolean = false) =>
  useBudgeQuery({
    ...queries.backoffice.fetchExportRequestFile(input),
    enabled,
    staleTime: 0,
    gcTime: 0,
  });
