import { AuditLogOutput } from "@budgeinc/budge-api";
import { Description, Divider, InfiniteScrollTable, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { usersApi } from "api/BudgeApi";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { initListFilters, ListFiltersContext } from "components/ListFilters/context";
import { getInitialFilters, hasFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";
import { getAuditsColumns } from "./columns";
import ViewRawDataButton from "./components/ViewRawDataButton";
import { getAuditsFiltersConfig } from "./filters";

const filtersConfig = getAuditsFiltersConfig("employee-transfers-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const AuditListTab = () => {
  const { state: pageState } = useEmployeeEntityContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      usersApi
        .searchAuditLogsForUser(pageState.employee?.userId!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({
          results: data,
        })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedContextValues}>
      <ListFiltersBar filtersConfig={filtersConfig} />
      <InfiniteScrollTable<AuditLogOutput>
        columns={getAuditsColumns()}
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        local={{
          empty: {
            m: 24,
            title: "No audits found",
            description: hasFilters(memoedContextValues)
              ? `No audits match these filters. Edit or clear all filters.`
              : undefined,
          },
          noMoreItems: "No more audits to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => (
          <>
            <Description>
              <Description.Item label="ID">{record.id}</Description.Item>
              <Description.Item label="Description">{record.description}</Description.Item>
            </Description>
            <Divider />
            <ViewRawDataButton audit={record} />
          </>
        )}
      />
    </ListFiltersContext.Provider>
  );
};

export default AuditListTab;
