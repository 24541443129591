import React from "react";
import { Box, EMPTY_VALUE_PLACEHOLDER, Stack, Text } from "@budgeinc/budge-ui-core";

interface OwnProps {
  ipAddress: string | undefined;
  geoLocation: string | undefined;
  direction?: "horizontal" | "vertical";
}

const GeoLocationDetails = ({ ipAddress, geoLocation, direction = "vertical" }: OwnProps) => {
  const isVertical = direction === "vertical";
  const StackComponent = isVertical ? Stack : Stack.Horizontal;

  return (
    <StackComponent alignItems={isVertical ? undefined : "center"} spacing={isVertical ? 0 : "xs"}>
      {ipAddress ? (
        <Box>
          <a
            onClick={e => e.stopPropagation()}
            target="_blank"
            href={`https://iplocation.com/?ip=${ipAddress}`}
            rel="noreferrer"
          >
            <Text color="primary.9">{ipAddress || EMPTY_VALUE_PLACEHOLDER}</Text>
          </a>
        </Box>
      ) : (
        <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
      )}
      {geoLocation && (
        <Text color="secondary" variant="bodySmall">
          {isVertical ? geoLocation : `(${geoLocation})`}
        </Text>
      )}
    </StackComponent>
  );
};

export default GeoLocationDetails;
