import { AccountSyncOutput, EntitySyncOutput, ErrorResponse, PaymentSyncOutput } from "@budgeinc/budge-api";
import { Box, Button, Stack, errorManager, modalManager, toast, RawJsonBox, Modal } from "@budgeinc/budge-ui-core";
import { AxiosError } from "axios";
import { useState } from "react";
import { methodApi } from "api/BudgeApi";

export type TMethodEntitySyncType = "accountSync" | "entitySync" | "paymentSync";

export type TMethodEntityDetailType =
  | "accountRaw"
  | "accountVerificationRaw"
  | "entityRaw"
  | "paymentRaw"
  | "merchantRaw"
  | "webhooksRaw";

export type TMethodEntityDetailWithIds = Exclude<TMethodEntityDetailType, "webhooksRaw">;

export const methodSyncByTypeAndId = async (
  type: TMethodEntitySyncType,
  id: string,
  dryRun: boolean = true
): Promise<AccountSyncOutput | EntitySyncOutput | PaymentSyncOutput> => {
  switch (type) {
    case "accountSync":
      return methodApi.syncAccountMethod(id, dryRun).then(r => r.data);
    case "entitySync":
      return methodApi.syncEntity(id, dryRun).then(r => r.data);
    case "paymentSync":
      return methodApi.syncPayment(id, dryRun).then(r => r.data);
  }
};

export const getMethodEntityDisplayNameByType = (type: TMethodEntitySyncType): string | undefined => {
  switch (type) {
    case "accountSync":
      return "Account";
    case "entitySync":
      return "Client";
    case "paymentSync":
      return "Payment";
  }
};

export const fetchMethodDetailsByTypeAndId = async (type: TMethodEntityDetailWithIds, id: string): Promise<any> => {
  switch (type) {
    case "accountRaw":
      return methodApi.getAccountRawMethod(id);
    case "accountVerificationRaw":
      return methodApi.getAccountVerificationRaw(id);
    case "entityRaw":
      return methodApi.getEntityRaw(id);
    case "paymentRaw":
      return methodApi.getPaymentRaw(id);
    case "merchantRaw":
      return methodApi.getMerchantRaw(id);
  }
};

export const fetchAllWebhookDetails = async (): Promise<any> => methodApi.getAllWebhooksRaw();

export const openMethodDetailsModal = (type: TMethodEntityDetailType, payload: any) =>
  modalManager.openModal({
    size: 750,
    onClose: modalManager.closeActiveModal,
    children: (
      <>
        <Modal.Header title={getMethodDetailsModalTitleByType(type)} onClose={() => modalManager.closeActiveModal()} />
        <Box px="xl" pb="xl">
          <RawJsonBox data={payload} />
        </Box>
      </>
    ),
  });

export const openMethodSyncModal = <T,>({
  type,
  recordId,
  rawData,
  onSynced,
}: {
  type: TMethodEntitySyncType;
  rawData: any;
  recordId: string;
  onSynced?(payload: T): void;
}) => {
  const parsedRawData = JSON.parse(rawData);

  modalManager.openModal({
    size: 750,
    onClose: modalManager.closeAllModals,
    children: (
      <>
        <Modal.Header title={getMethodSyncModalTitleByType(type)} />
        <Stack spacing="md" px="xl" pb="xl">
          <RawJsonBox data={parsedRawData} />
          {parsedRawData.updated ? (
            <MethodConfirmSyncButton<T> type={type} recordId={recordId} onSynced={onSynced} />
          ) : (
            <Button
              title="No Change Found. Close"
              variant="gradient"
              color="primary"
              onPress={modalManager.closeActiveModal}
            />
          )}
        </Stack>
      </>
    ),
  });
};

export const getMethodSyncModalTitleByType = (type: TMethodEntitySyncType): string => {
  switch (type) {
    case "accountSync":
      return "Method Account Sync";
    case "entitySync":
      return "Method Entity Sync";
    case "paymentSync":
      return "Method Payment Details";
  }
};

export const getMethodDetailsModalTitleByType = (type: TMethodEntityDetailType): string => {
  switch (type) {
    case "accountRaw":
      return "Method Account Details";
    case "accountVerificationRaw":
      return "Method Account Vertification Details";
    case "entityRaw":
      return "Method Entity Details";
    case "paymentRaw":
      return "Method Payment Details";
    case "merchantRaw":
      return "Method Merchant Details";
    case "webhooksRaw":
      return "Method Webhook Details";
  }
};

export const getMethodInputLabelByType = (type: Exclude<TMethodEntityDetailType, "webhooksRaw">): string => {
  switch (type) {
    case "accountRaw":
      return "Method Account ID";
    case "accountVerificationRaw":
      return "Method Account ID";
    case "entityRaw":
      return "Method Entity ID";
    case "paymentRaw":
      return "Method Payment ID";
    case "merchantRaw":
      return "Method Merchant ID";
  }
};

export const MethodConfirmSyncButton = <T,>({
  type,
  recordId,
  onSynced,
}: {
  recordId: string;
  type: TMethodEntitySyncType;
  onSynced?(payload: T): void;
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirmSync = async () => {
    setLoading(true);
    return methodSyncByTypeAndId(type, recordId, false)
      .then(payload => {
        onSynced?.(payload as T);
        modalManager.closeActiveModal();
        toast.success({
          message: `${getMethodEntityDisplayNameByType(type)} synced successfully`,
        });
      })
      .catch((e: AxiosError<ErrorResponse>) =>
        errorManager.showError({
          error: e.response?.data,
          customMessage:
            (e.response?.data as any)?.data?.exceptionData?.exceptionMessage ||
            (e.response?.data as any)?.data?.exceptionMessage,
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <Button title="Confirm Sync" color="primary" variant="gradient" loading={loading} onPress={handleConfirmSync} />
  );
};
