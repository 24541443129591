import React from "react";
import { Box, Button, modalManager, TButtonProps, RawJsonBox, Modal } from "@budgeinc/budge-ui-core";

export type TShowRawDataButtonProps = Omit<TButtonProps, "onPress"> & {
  modalTitle?: string;
  modalSize?: number;
  data: any;
};

const ShowRawDataButton = ({ data, modalTitle, modalSize, ...buttonProps }: TShowRawDataButtonProps) => {
  const handleShowRawData = () =>
    modalManager.openModal({
      size: modalSize || 750,
      onClose: modalManager.closeActiveModal,
      children: (
        <>
          <Modal.Header title={modalTitle || "Raw Data"} onClose={modalManager.closeActiveModal} />
          <Box px="lg" pb="lg">
            <RawJsonBox data={data} />
          </Box>
        </>
      ),
    });

  return (
    <Button
      title="Show Raw Data"
      size="md"
      variant="gradient"
      color="primary"
      {...buttonProps}
      onPress={handleShowRawData}
    />
  );
};

export default ShowRawDataButton;
