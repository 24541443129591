import FilterCollapse from "components/ListFilters/FilterCollapse";
import {
  AppSecureStorage,
  BuildValidationSchema,
  Checkbox,
  DatePickerInput,
  DateSchema,
  FormItem,
  Input,
  Maybe,
  NumberInput,
  Stack,
  yup,
} from "@budgeinc/budge-ui-core";
import { addDays, subDays } from "date-fns";
import {
  BooleanFilterValues,
  TBooleanFilterConfig,
  TCheckboxGroupFilterConfig,
  TFiltersConfig,
  TRangeFilterConfig,
  TStringFilterConfig,
} from "./types";

export const renderCheckboxGroupFilter = (config: TCheckboxGroupFilterConfig<any>) => (
  <FilterCollapse key={config.apiAttribute as string} defaultOpen={config.defaultOpen} trigger={config.title}>
    <FormItem name={config.apiAttribute as string}>
      <Checkbox.Group options={config.options} />
    </FormItem>
  </FilterCollapse>
);

export const renderBooleanFilter = (config: TBooleanFilterConfig<any>) => (
  <FilterCollapse key={config.apiAttribute as string} defaultOpen={config.defaultOpen} trigger={config.title}>
    <FormItem name={config.apiAttribute as string}>
      <Checkbox.Group
        options={[
          { label: config.labelTrue || "Yes", value: BooleanFilterValues.YES },
          { label: config.labelFalse || "No", value: BooleanFilterValues.NO },
        ]}
      />
    </FormItem>
  </FilterCollapse>
);

export const renderRangeFilter = (config: TRangeFilterConfig<any>) => {
  if (config.subType === "number") {
    return (
      <FilterCollapse key={config.apiAttributeFrom as string} defaultOpen={config.defaultOpen} trigger={config.title}>
        <Stack>
          <FormItem name={config.apiAttributeFrom as string}>
            <NumberInput label={config.labelFrom || "From"} />
          </FormItem>
          <FormItem name={config.apiAttributeTo as string}>
            <NumberInput label={config.labelTo || "To"} />
          </FormItem>
        </Stack>
      </FilterCollapse>
    );
  }

  if (config.subType === "date") {
    return (
      <FilterCollapse key={config.apiAttributeFrom as string} defaultOpen={config.defaultOpen} trigger={config.title}>
        <Stack>
          <FormItem name={config.apiAttributeFrom as string}>
            <DatePickerInput
              label={config.labelFrom || "From"}
              // maxDate={getFilterDateFromMaxDate(form.getFieldMeta<Date>(config.apiAttributeTo).value)}
            />
          </FormItem>
          <FormItem name={config.apiAttributeTo as string}>
            <DatePickerInput
              label={config.labelTo || "To"}
              // minDate={getFilterDateToMinDate(form.getFieldMeta<Date>(config.apiAttributeFrom).value)}
            />
          </FormItem>
        </Stack>
      </FilterCollapse>
    );
  }
};

export const renderStringFilter = (config: TStringFilterConfig<any>) => (
  <FilterCollapse key={config.apiAttribute as string} defaultOpen={config.defaultOpen} trigger={config.title}>
    <FormItem name={config.apiAttribute as string}>
      <Input label={config.label} />
    </FormItem>
  </FilterCollapse>
);

export const getFiltersValidationSchema = (filtersConfig: TFiltersConfig<any>) => {
  let schema = BuildValidationSchema({});

  filtersConfig.filters.forEach(filter => {
    if (filter.type === "boolean") {
      schema = schema.concat(
        BuildValidationSchema({
          [filter.apiAttribute]: yup.array().of(yup.string().oneOf(Object.values(BooleanFilterValues))),
        })
      );
    } else if (filter.type === "checkboxgroup") {
      schema = schema.concat(
        BuildValidationSchema({
          [filter.apiAttribute]: yup.array().of(yup.string()),
        })
      );
    } else if (filter.type === "range" && filter.subType === "date") {
      schema = schema.concat(
        BuildValidationSchema({
          [filter.apiAttributeFrom]: DateSchema().nullable(),
          [filter.apiAttributeTo]: DateSchema().nullable(),
        })
      );
    } else if (filter.type === "range" && filter.subType === "number") {
      schema = schema.concat(
        BuildValidationSchema({
          [filter.apiAttributeFrom]: yup.number().nullable(),
          [filter.apiAttributeTo]: yup.number().nullable(),
        })
      );
    } else if (filter.type === "string") {
      schema = schema.concat(
        BuildValidationSchema({
          [filter.apiAttribute]: yup.string().nullable(),
        })
      );
    }
  });

  return schema;
};

export const getInitialFilters = <TSearchCriteria,>(
  filtersConfig: TFiltersConfig<TSearchCriteria>
): TSearchCriteria => {
  const filters = getPersistedFilters<any>(filtersConfig.persistId);

  return filtersConfig.filters.reduce(
    (prev, current) => {
      if (current.type === "range") {
        prev[current.apiAttributeFrom] = filters?.[current.apiAttributeFrom] || undefined;
        prev[current.apiAttributeTo] = filters?.[current.apiAttributeTo] || undefined;
      } else if (current.type === "boolean") {
        const savedValue = filters?.[current.apiAttribute];
        prev[current.apiAttribute] = savedValue ? getBooleanDataFilterValue(savedValue) : undefined;
      } else if (current.type === "checkboxgroup" || current.type === "string") {
        prev[current.apiAttribute] = filters?.[current.apiAttribute] || undefined;
      }

      return prev;
    },
    {
      genericSearchLike: undefined,
    } as any
  );
};

export const getBooleanDataFilterValue = (values: BooleanFilterValues[]): boolean | undefined => {
  if (!values.length || values.length === 2) return undefined;

  return values.includes(BooleanFilterValues.YES);
};

export const getInitialBooleanDataFilterValue = (value: Maybe<boolean>): BooleanFilterValues[] => {
  if (value === null || value === undefined) return [];

  return value ? [BooleanFilterValues.YES] : [BooleanFilterValues.NO];
};

export const getFilterDateToMinDate = (startDate: Date): Date | undefined =>
  startDate ? addDays(startDate, 1) : undefined;

export const getFilterDateFromMaxDate = (endDate: Date): Date | undefined =>
  endDate ? subDays(endDate, 1) : undefined;

export const persistFilters = (persistId: string, filters: any) => {
  AppSecureStorage.save(persistId, JSON.stringify(filters));
};

export const getPersistedFilters = <T,>(persistId: string | undefined): T | null => {
  const filters = persistId ? AppSecureStorage.get(persistId) : null;

  return filters ? (JSON.parse(filters) as T) : null;
};

export const hasFilters = (filters: any) => Object.values(filters).some(value => !!value);
