import React from "react";
import { CloseIcon, RoundIcon, Stack, Text } from "@budgeinc/budge-ui-core";

const Unauthorized = () => (
  <Stack alignItems="center" spacing="xl" mt={50}>
    <RoundIcon icon={CloseIcon} color="red" size="2xl" />
    <Text variant="bodyLarge">Access to this page is restricted and currently not authorized for your account.</Text>
  </Stack>
);

export default Unauthorized;
