import {
  FinancialAccountTransferSearchCriteriaInput,
  FinancialAccountTransferOutputStatusEnum,
} from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getTransfersFiltersConfig = (
  persistId: string,
  options?: {
    showSearchBar?: boolean;
  }
): TFiltersConfig<FinancialAccountTransferSearchCriteriaInput> => ({
  persistId,
  showSearchBar: options?.showSearchBar === undefined ? true : (options.showSearchBar as any),
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<FinancialAccountTransferSearchCriteriaInput>,
    {
      type: "range",
      subType: "date",
      apiAttributeFrom: "processedDateFrom",
      apiAttributeTo: "processedDateTo",
      title: "Processed Date",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "status",
      title: "Status",
      options: Object.values(FinancialAccountTransferOutputStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
  ],
});
