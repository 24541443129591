import React from "react";
import { Box, Spinner, TBoxProps, TSpinnerProps } from "@budgeinc/budge-ui-core";

type OwnProps = TSpinnerProps & TBoxProps;

const CenteredSpinner = ({ size = "md", color, ...boxProps }: OwnProps) => (
  <Box alignItems="center" justifyContent="center" w="100%" h="100%" {...boxProps}>
    <Spinner size={size} color={color} />
  </Box>
);

export default CenteredSpinner;
