import { loginsCrossTenantApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import LoginsTable from "components/Table/LoginsTable";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { getLoginsFiltersConfig } from "components/Table/LoginsTable/filters";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import { LoginSearchCriteriaInput } from "@budgeinc/budge-api";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";

const filtersConfig = getLoginsFiltersConfig("employee-logins-filters", {
  showSearchBar: false,
});
const { ListFiltersReducer } = initListFilters(filtersConfig);

const EmployeeLoginsListTab = () => {
  const {
    state: { employee },
    employerId,
  } = useEmployeeEntityContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      loginsCrossTenantApi
        .searchEmployeeLogins(employerId!, employee?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo<TListFilterContextType<LoginSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedContextValues}>
      <ListFiltersBar filtersConfig={filtersConfig} />
      <LoginsTable requestState={requestState} keyExtractor={item => item.id} onEndReached={onEndReached} />
    </ListFiltersContext.Provider>
  );
};

export default EmployeeLoginsListTab;
