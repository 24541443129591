import React, { useEffect, useState } from "react";
import { Select, Stack, TSelectOption, TSelectProps, Text, useFormikField } from "@budgeinc/budge-ui-core";
import { employeesApi, employeesCrossTenantApi, employersApi } from "api/BudgeApi";

type OwnProps = Omit<TSelectProps, "enableSearch" | "onSearch" | "options"> & {
  employerId: string;
  employeeId?: string;
  idsToExclude?: string[];
};

const EmployeeSearchSelect = ({ employerId, employeeId, ...props }: OwnProps) => {
  const { field } = useFormikField();
  const [initialOptions, setInitialOptions] = useState<TSelectOption[]>([]);

  useEffect(() => {
    if (field?.value && employerId) {
      employeesCrossTenantApi.getEmployee(employerId, field.value).then(({ data }) =>
        setInitialOptions([
          {
            label: `${data.firstName} ${data.lastName}`,
            value: data.id,
          },
        ])
      );
    }
  }, []);

  const handleSearch = async (value: string): Promise<TSelectOption[]> => {
    const results = await employeesCrossTenantApi.searchEmployees(employerId!, { genericSearchLike: value }, "0", "20");

    return results.data
      .filter(({ id }) => id !== employeeId)
      .map(employee => ({
        label: `${employee.firstName} ${employee.lastName}`,
        customContent: (
          <Stack spacing={0}>
            <Text>{`${employee.firstName} ${employee.lastName}`}</Text>
            <Text variant="bodySmall" color="secondary">
              {employee.email}
            </Text>
          </Stack>
        ),
        value: employee.id,
      }));
  };

  return <Select {...props} enableSearch onSearch={handleSearch} includeEmptyOption options={initialOptions} />;
};

export default EmployeeSearchSelect;
