import { InviteSearchCriteriaInput, InviteSearchCriteriaInputStatusEnum } from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getInvitesFiltersConfig = (persistId: string): TFiltersConfig<InviteSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<InviteSearchCriteriaInput>,
    {
      type: "checkboxgroup",
      apiAttribute: "status",
      title: "Status",
      options: Object.values(InviteSearchCriteriaInputStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
  ],
});
