import { TFilter } from "./types";

export const customerCreationDateRangeFilter: TFilter<any> = {
  type: "range",
  subType: "date",
  apiAttributeFrom: "customerCreationDateFrom",
  apiAttributeTo: "customerCreationDateTo",
  title: "Customer Creation Date",
  defaultOpen: true,
};
