import {
  FinancialAccountBalanceOutput,
  FinancialAccountBalanceSearchCriteriaInput,
  FinancialAccountOutputAccountTypeEnum,
  FinancialAccountBalanceOutputSourceEnum,
} from "@budgeinc/budge-api";
import {
  Description,
  Divider,
  EMPTY_VALUE_PLACEHOLDER,
  formatMoney,
  formatPercent,
  InfiniteScrollTable,
  Text,
  useInfiniteScrollList,
} from "@budgeinc/budge-ui-core";
import { accountsCrossTenantApi } from "api/BudgeApi";
import DateDisplay from "components/DateDisplay";
import { useAccountEntityContext } from "features/accounts/contexts/AccountEntityContext";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";
import { getAccountBalanceHistoryFiltersConfig } from "./filters";
import { getAccountBalanceHistoryColumns } from "./columns";

const filtersConfig = getAccountBalanceHistoryFiltersConfig("employee-account-history-filter-id");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const AccountHistoryTab = () => {
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const {
    state: { employer },
  } = useEmployerContext();
  const {
    state: { account, employee },
  } = useAccountEntityContext();

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      accountsCrossTenantApi
        .getEmployeeAccountBalanceHistory(
          employer?.id!,
          employee?.id!,
          account?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo<TListFilterContextType<FinancialAccountBalanceSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  if (!account) return null;

  return (
    <ListFiltersContext.Provider value={memoedContextValues}>
      <ListFiltersBar filtersConfig={filtersConfig} />
      <InfiniteScrollTable<FinancialAccountBalanceOutput>
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        columns={getAccountBalanceHistoryColumns()}
        local={{
          empty: {
            title: "No balance history",
          },
          noMoreItems: "No more balance history to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => (
          <>
            <Description>
              <Description.Item label="Interest Rate">
                <Text>
                  {formatPercent({
                    value: record.interestRate,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Last Payment">
                <Text>
                  {formatMoney({
                    amountCents: record.lastPaymentAmountCents,
                  })}
                </Text>
              </Description.Item>
              <Description.Item label="Last Minimum Payment">
                <Text>
                  {formatMoney({
                    amountCents: record.lastStatementMinimumPaymentAmountCents,
                  })}
                </Text>
              </Description.Item>
              <>
                {account.accountType === FinancialAccountOutputAccountTypeEnum.CreditCard &&
                  record.source === FinancialAccountBalanceOutputSourceEnum.Plaid && (
                    <>
                      <Divider orientation="left" orientationMargin={0} spacing="xl">
                        <Text color="secondary">Last Statement</Text>
                      </Divider>
                      <Description.Item label="Issue Date">
                        {record.lastStatementIssueDate ? (
                          <DateDisplay value={record.lastStatementIssueDate} />
                        ) : (
                          EMPTY_VALUE_PLACEHOLDER
                        )}
                      </Description.Item>
                      <Description.Item label="Balance">
                        <Text>
                          {formatMoney({
                            amountCents: record.lastStatementBalanceAmountCents,
                          })}
                        </Text>
                      </Description.Item>
                      <Description.Item label="Minimum Payment">
                        <Text>
                          {formatMoney({
                            amountCents: record.lastStatementMinimumPaymentAmountCents,
                          })}
                        </Text>
                      </Description.Item>
                      <Description.Item label="Due Date">
                        {record.lastStatementNextPaymentDueDate ? (
                          <DateDisplay value={record.lastStatementNextPaymentDueDate} showTime />
                        ) : (
                          EMPTY_VALUE_PLACEHOLDER
                        )}
                      </Description.Item>
                    </>
                  )}
              </>
            </Description>
          </>
        )}
      />
    </ListFiltersContext.Provider>
  );
};

export default AccountHistoryTab;
