import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import ReferralPaymentsTable from "components/Table/Referral/PaymentsList";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { backofficeApi } from "api/BudgeApi";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { getReferralPaymentFiltersConfig } from "components/Table/Referral/PaymentsList/filters";
import { initListFilters, ListFiltersContext } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";
import ExportReferralPaymentsButton from "./components/ExportReferralPaymentsButton";

const filtersConfig = getReferralPaymentFiltersConfig("employer-referral-payments-list-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const ReferralPaymentsListTab = () => {
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      backofficeApi
        .searchReferralPayments(filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedContextValues}>
      <ListFiltersBar
        filtersConfig={filtersConfig}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.Referrals]}>
            <ExportReferralPaymentsButton />
          </ExportAccessScopeValidator>
        }
      />
      <ReferralPaymentsTable
        includeReferrer
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
      />
    </ListFiltersContext.Provider>
  );
};

export default ReferralPaymentsListTab;
