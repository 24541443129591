/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import { CreditScoreOutput } from "@budgeinc/budge-api";
import { InfiniteScrollTable, Description, i18n, EMPTY_VALUE_PLACEHOLDER, Divider } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import { TInfiniteScrollDataTableProps } from "../types";
import { getCreditScoreColumns } from "./columns";

type OwnProps = TInfiniteScrollDataTableProps<CreditScoreOutput> & {
  employerId?: string;
};

const CreditScoresTable = ({ emptyLocal, employerId, ...props }: OwnProps) => (
  <InfiniteScrollTable<CreditScoreOutput>
    {...props}
    columns={getCreditScoreColumns(employerId)}
    local={{
      empty: {
        m: 24,
        title: "No credit scores found",
      },
      noMoreItems: "No more credit scores to load",
    }}
    contentContainerStyle={{ marginBottom: 16 }}
    expandable={record => (
      <>
        <Description>
          <Description.Item label="ID">{record.id}</Description.Item>
          <Description.Item label="Method Credit Score ID">{record.methodCreditScoreId}</Description.Item>
          <Description.Item label="Model">
            {i18n.t(`enums.creditScore.model.${record.model}`, {
              defaultValue: EMPTY_VALUE_PLACEHOLDER,
            })}
          </Description.Item>
          <Description.Item label="Source">
            {i18n.t(`enums.creditScore.source.${record.source}`, {
              defaultValue: EMPTY_VALUE_PLACEHOLDER,
            })}
          </Description.Item>
        </Description>
        <Divider />
        <Description>
          <Description.Item label="Updated At">
            <DateDisplay numberOfLines={1} value={record.updatedAt} showTime />
          </Description.Item>
          <Description.Item label="Created At">
            <DateDisplay numberOfLines={1} value={record.createdAt} showTime />
          </Description.Item>
        </Description>
      </>
    )}
  />
);

export default CreditScoresTable;
