import React, { PropsWithChildren } from "react";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { TAccessOperation, TScopeRule, AccessScopeValidator } from "@budgeinc/budge-ui-core";

interface OwnProps {
  rule: TScopeRule;
}

const ExportAccessScopeValidator = ({ children, rule }: PropsWithChildren<OwnProps>) => (
  <AccessScopeValidator
    op={TAccessOperation.READ}
    rule={[
      ...rule,
      {
        OR: [UserWhoamiOutputScopesEnum.ExportsWithPii, UserWhoamiOutputScopesEnum.ExportsWithoutPii],
      },
    ]}
  >
    {children}
  </AccessScopeValidator>
);

export default ExportAccessScopeValidator;
