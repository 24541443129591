import { EmployeeSearchCriteriaInput } from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";

export const getCustomersFiltersConfig = (persistId: string): TFiltersConfig<EmployeeSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<EmployeeSearchCriteriaInput>,
    {
      type: "boolean",
      apiAttribute: "isMissingEmployeeOnboardingData",
      title: "Clients KYC/onboarding",
      labelTrue: "Excluded",
      labelFalse: "Not Excluded",
    },
    {
      type: "boolean",
      apiAttribute: "isExcluded",
      title: "Excluded clients",
      labelTrue: "Excluded",
      labelFalse: "Not Excluded",
    },
    {
      type: "boolean",
      apiAttribute: "isKpiExcluded",
      title: "KPI Excluded",
      labelTrue: "Excluded",
      labelFalse: "Not Excluded",
    },
    {
      type: "boolean",
      apiAttribute: "isActiveCampaignExcluded",
      title: "Active Campaign Excluded",
      labelTrue: "Excluded",
      labelFalse: "Not Excluded",
    },
  ],
});
