import React from "react";
import { Switch } from "@budgeinc/budge-ui-core";
import { useMutation } from "@tanstack/react-query";
import { accountsApi } from "api/BudgeApi";
import { FinancialAccountOutput } from "@budgeinc/budge-api";

const AggregatorDataUpdatesIgnoredSwitch = ({
  account,
  onAccountChange,
}: {
  account: FinancialAccountOutput;
  onAccountChange(account: FinancialAccountOutput): void;
}) => {
  const mutation = useMutation({
    mutationFn: async (checked: boolean) => {
      const response = accountsApi.updateEmployeeFinancialAccount(account.employeeId, account.id, {
        isIgnoreAggregatorData: checked,
      });
      return (await response).data;
    },
    onSuccess: updatedAccount => onAccountChange(updatedAccount),
  });

  return (
    <Switch
      label="Aggregator Data Updates Ignored"
      mt="md"
      checked={account.isIgnoreAggregatorData}
      onValueChange={checked => {
        mutation.mutate(checked);
      }}
    />
  );
};

export default AggregatorDataUpdatesIgnoredSwitch;
