import React, { useMemo } from "react";
import { Stack } from "@budgeinc/budge-ui-core";
import BaseListFiltersBar, { TBaseListFiltersBarProps } from "./BaseListFiltersBar";
import { TFiltersConfig } from "./types";
import {
  getBooleanDataFilterValue,
  getFiltersValidationSchema,
  getInitialBooleanDataFilterValue,
  renderBooleanFilter,
  renderCheckboxGroupFilter,
  renderRangeFilter,
  renderStringFilter,
} from "./utils";
import { useListFiltersContext } from "./context";

const ListFiltersBar = <TSearchCriteria,>({
  filtersConfig,
  ...props
}: TBaseListFiltersBarProps & {
  filtersConfig: TFiltersConfig<TSearchCriteria>;
}) => {
  const validationSchema = useMemo(() => getFiltersValidationSchema(filtersConfig), [filtersConfig.filters]);

  const { dispatch, state: filters } = useListFiltersContext();

  const handleOnSearch = (value: string | undefined) =>
    dispatch({
      type: "updateFilters",
      data: {
        genericSearchLike: value,
      },
    });

  return (
    <BaseListFiltersBar
      {...props}
      persistId={filtersConfig.persistId}
      showSearchBar={filtersConfig.showSearchBar}
      searchPlaceHolder={filtersConfig.searchBarPlaceholder}
      showAdvancedFilters={filtersConfig.filters.length > 0 ? filtersConfig.showAdvancedFilters : false}
      onSearchChange={handleOnSearch}
      formProps={{
        onSubmit: async (values: any) => {
          const updateFilters = filtersConfig.filters.reduce((prev, current) => {
            if (current.type === "range") {
              prev[current.apiAttributeFrom] = values?.[current.apiAttributeFrom]?.toISOString() || undefined;
              prev[current.apiAttributeTo] = values?.[current.apiAttributeTo]?.toISOString() || undefined;
            } else if (current.type === "boolean") {
              prev[current.apiAttribute] = values?.[current.apiAttribute]
                ? getBooleanDataFilterValue(values[current.apiAttribute])
                : undefined;
            } else if (current.type === "checkboxgroup") {
              prev[current.apiAttribute] = values?.[current.apiAttribute]?.length
                ? values[current.apiAttribute]
                : undefined;
            } else if (current.type === "string") {
              prev[current.apiAttribute] = values?.[current.apiAttribute]?.toString();
            }

            return prev;
          }, {} as any);

          dispatch({
            type: "updateFilters",
            data: updateFilters,
          });
        },
        validationSchema,
        getInitialValues: reset => {
          if (filters && !reset) {
            return filtersConfig.filters.reduce((prev, current) => {
              if (current.type === "range") {
                const dateFrom = filters?.[current.apiAttributeFrom];
                const dateTo = filters?.[current.apiAttributeTo];
                prev[current.apiAttributeFrom] = dateFrom ? new Date(dateFrom) : undefined;
                prev[current.apiAttributeTo] = dateTo ? new Date(dateTo) : undefined;
              } else if (current.type === "boolean") {
                prev[current.apiAttribute] = getInitialBooleanDataFilterValue(filters?.[current.apiAttribute]);
              } else if (current.type === "checkboxgroup") {
                prev[current.apiAttribute] = filters?.[current.apiAttribute] || [];
              } else if (current.type === "string") {
                prev[current.apiAttribute] = filters?.[current.apiAttribute]?.toString();
              }

              return prev;
            }, {} as any);
          }

          return filtersConfig.filters.reduce((prev, current) => {
            if (current.type === "range") {
              prev[current.apiAttributeFrom] = undefined;
              prev[current.apiAttributeTo] = undefined;
            } else if (current.type === "checkboxgroup") {
              prev[current.apiAttribute] = [];
            } else if (current.type === "string" || current.type === "boolean") {
              prev[current.apiAttribute] = undefined;
            }

            return prev;
          }, {} as any);
        },
      }}
    >
      <Stack spacing="sm">
        {filtersConfig.filters.map(filter => {
          switch (filter.type) {
            case "boolean":
              return renderBooleanFilter(filter);
            case "checkboxgroup":
              return renderCheckboxGroupFilter(filter);
            case "range":
              return renderRangeFilter(filter);
            case "string":
              return renderStringFilter(filter);
            default:
              return null;
          }
        })}
      </Stack>
    </BaseListFiltersBar>
  );
};

export default ListFiltersBar;
