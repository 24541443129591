import {
  FinancialAccountBalanceSearchCriteriaInputBalanceSourceEnum,
  FinancialAccountBalanceSearchCriteriaInput,
} from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getAccountBalanceHistoryFiltersConfig = (
  persistId: string
): TFiltersConfig<FinancialAccountBalanceSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<FinancialAccountBalanceSearchCriteriaInput>,
    {
      type: "range",
      subType: "date",
      apiAttributeFrom: "asOfDateFrom",
      apiAttributeTo: "asOfDateTo",
      title: "As Of Date",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "balanceSource",
      title: "Status",
      options: Object.values(FinancialAccountBalanceSearchCriteriaInputBalanceSourceEnum).map(source => ({
        label: formatEnumValue(source),
        value: source,
      })),
    },
  ],
});
