import {
  CreditScoreSearchCriteriaInput,
  FinancialAccountOutputAccountSourceEnum,
  FinancialAccountOutputAccountTypeEnum,
  FinancialAccountSearchCriteriaInput,
  FinancialAccountOutputMethodCanReceivePaymentsEnum,
} from "@budgeinc/budge-api";
import { i18n } from "@budgeinc/budge-ui-core";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getAccountFiltersConfig = (persistId: string): TFiltersConfig<FinancialAccountSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<FinancialAccountSearchCriteriaInput>,
    {
      type: "range",
      subType: "date",
      apiAttributeFrom: "balanceAsOfDateFrom",
      apiAttributeTo: "balanceAsOfDateTo",
      title: "Balance Update As Of Date",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "accountType",
      title: "Rating",
      options: Object.values(FinancialAccountOutputAccountTypeEnum).map(type => ({
        label: formatEnumValue(type),
        value: type,
      })),
    },
    {
      type: "checkboxgroup",
      apiAttribute: "accountSource",
      title: "Status",
      options: Object.values(FinancialAccountOutputAccountSourceEnum).map(source => ({
        label: i18n.get(`enums.accountSources.${source}`),
        value: source,
      })),
    },
    {
      type: "checkboxgroup",
      apiAttribute: "methodCanReceivePayments",
      title: "Can Receive Payment",
      options: Object.values(FinancialAccountOutputMethodCanReceivePaymentsEnum).map(val => ({
        label: i18n.get(`enums.methodCanReceivePayment.${val}`),
        value: val,
      })),
    },
    {
      type: "string",
      apiAttribute: "methodAccountId",
      title: "Method Account",
      label: "Account ID",
    },
    {
      type: "string",
      apiAttribute: "methodEntityId",
      title: "Method Entity",
      label: "Entity ID",
    },
    {
      type: "boolean",
      apiAttribute: "hasMethodError",
      title: "Method Error",
    },
    {
      type: "boolean",
      apiAttribute: "hasPlaidError",
      title: "Plaid Error",
    },
  ],
});
