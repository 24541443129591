import {
  CreditScoreSearchCriteriaInput,
  CreditScoreSearchCriteriaInputStatusEnum,
  CreditScoreSearchCriteriaInputRatingEnum,
  CreditScoreSearchCriteriaInputSourceEnum,
  CreditScoreSearchCriteriaInputModelEnum,
} from "@budgeinc/budge-api";
import { i18n } from "@budgeinc/budge-ui-core";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getCreditScoreFiltersConfig = (persistId: string): TFiltersConfig<CreditScoreSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<CreditScoreSearchCriteriaInput>,
    {
      type: "boolean",
      apiAttribute: "isLatest",
      title: "Is Latest",
    },
    {
      type: "boolean",
      apiAttribute: "isPrevious",
      title: "Is Previous",
    },
    {
      type: "range",
      subType: "number",
      apiAttributeFrom: "creditScoreLowValue",
      apiAttributeTo: "creditScoreHighValue",
      title: "Score Value",
      labelFrom: "Score Low Value",
      labelTo: "Score Low High",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "status",
      title: "Status",
      options: Object.values(CreditScoreSearchCriteriaInputStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
    {
      type: "checkboxgroup",
      apiAttribute: "rating",
      title: "Rating",
      options: Object.values(CreditScoreSearchCriteriaInputRatingEnum).map(rating => ({
        label: formatEnumValue(rating),
        value: rating,
      })),
    },
    {
      type: "checkboxgroup",
      apiAttribute: "source",
      title: "Source",
      options: Object.values(CreditScoreSearchCriteriaInputSourceEnum).map(source => ({
        label: formatEnumValue(source),
        value: source,
      })),
    },
    {
      type: "checkboxgroup",
      apiAttribute: "model",
      title: "Model",
      options: Object.values(CreditScoreSearchCriteriaInputModelEnum).map(model => ({
        label: i18n.t(`enums.creditScore.model.${model}`),
        value: model,
      })),
    },
  ],
});
