import {
  BuildValidationSchema,
  FormItem,
  FormProvider,
  Modal,
  Stack,
  Switch,
  yup,
  Select,
  getDayOfMonthDisplay,
  TSelectOption,
  TAccessOperation,
  useAccessScopeValidator,
} from "@budgeinc/budge-ui-core";
import React from "react";
import { useFormik } from "formik";
import { formatEnumValue } from "utils/format";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { ExportScheduleEnum, TExportMutationOptions } from "./types";

enum Frequency {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
}

const EVERY_DAY_OPTION = "every_day";

const getDayOptionsByFrequency = (
  frequency: Frequency
): {
  formatOption: (day: any) => TSelectOption;
  options: string[];
} => {
  if (frequency === Frequency.DAILY) {
    return {
      formatOption: (day: ExportScheduleEnum) => ({
        label: formatEnumValue(day),
        value: day,
      }),
      options: [EVERY_DAY_OPTION],
    };
  }

  if (frequency === Frequency.WEEKLY) {
    return {
      formatOption: (day: ExportScheduleEnum) => ({
        label: formatEnumValue(day),
        value: day,
      }),
      options: [
        ExportScheduleEnum.Monday,
        ExportScheduleEnum.Tuesday,
        ExportScheduleEnum.Wednesday,
        ExportScheduleEnum.Thursday,
        ExportScheduleEnum.Friday,
        ExportScheduleEnum.Saturday,
        ExportScheduleEnum.Sunday,
      ],
    };
  }

  return {
    formatOption: (day: ExportScheduleEnum) => {
      if (day === ExportScheduleEnum.LastDayOfMonth) {
        return {
          label: formatEnumValue(day),
          value: day,
        };
      }

      return {
        label: getDayOfMonthDisplay(parseInt(day.replace("D", ""))),
        value: day,
      };
    },
    options: [
      ExportScheduleEnum.D01,
      ExportScheduleEnum.D02,
      ExportScheduleEnum.D03,
      ExportScheduleEnum.D04,
      ExportScheduleEnum.D05,
      ExportScheduleEnum.D06,
      ExportScheduleEnum.D07,
      ExportScheduleEnum.D08,
      ExportScheduleEnum.D09,
      ExportScheduleEnum.D10,
      ExportScheduleEnum.D11,
      ExportScheduleEnum.D12,
      ExportScheduleEnum.D13,
      ExportScheduleEnum.D14,
      ExportScheduleEnum.D15,
      ExportScheduleEnum.D16,
      ExportScheduleEnum.D17,
      ExportScheduleEnum.D18,
      ExportScheduleEnum.D19,
      ExportScheduleEnum.D20,
      ExportScheduleEnum.D21,
      ExportScheduleEnum.D22,
      ExportScheduleEnum.D23,
      ExportScheduleEnum.D24,
      ExportScheduleEnum.D25,
      ExportScheduleEnum.D26,
      ExportScheduleEnum.D27,
      ExportScheduleEnum.D28,
      ExportScheduleEnum.LastDayOfMonth,
    ],
  };
};

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  onClosed?: () => void;
  onPressSchedule(options: TExportMutationOptions): Promise<any>;
}

enum FIELDS {
  SCHEDULE = "schedule",
  FREQUENCY = "frequency",
  DAY = "day",
  INCLUDE_PII = "includePii",
}

const validationSchema = BuildValidationSchema({
  [FIELDS.INCLUDE_PII]: yup.boolean(),
  [FIELDS.FREQUENCY]: yup.string().oneOf(Object.values(Frequency)).required(),
  [FIELDS.DAY]: yup.string(),
});

const ExportModal = ({ isOpen, onClose, onClosed, onPressSchedule }: OwnProps) => {
  const { validateAccessScopes } = useAccessScopeValidator();

  const form = useFormik({
    initialValues: {
      [FIELDS.SCHEDULE]: true,
      [FIELDS.FREQUENCY]: Frequency.MONTHLY,
      [FIELDS.DAY]: ExportScheduleEnum.D01,
      [FIELDS.INCLUDE_PII]: false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      if (!values[FIELDS.SCHEDULE]) {
        return onPressSchedule({
          scheduling: ExportScheduleEnum.None,
          includePii: values[FIELDS.INCLUDE_PII],
        }).then(() => onClose());
      }

      if (values[FIELDS.FREQUENCY] === Frequency.DAILY) {
        return onPressSchedule({
          scheduling: ExportScheduleEnum.Daily,
          includePii: values[FIELDS.INCLUDE_PII],
        }).then(() => onClose());
      }

      return onPressSchedule({
        scheduling: values[FIELDS.DAY],
        includePii: values[FIELDS.INCLUDE_PII],
      }).then(() => onClose());
    },
  });

  const handleOnClosed = () => {
    form.resetForm();
    onClosed?.();
  };

  const optionMeta = getDayOptionsByFrequency(form.getFieldMeta(FIELDS.FREQUENCY).value);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onClosed={handleOnClosed}
      header={{
        title: "Export Settings",
      }}
      footer={{
        okButtonProps: {
          title: form.getFieldMeta(FIELDS.SCHEDULE).value ? "Schedule" : "Export",
          loading: form.isSubmitting,
          onPress: form.submitForm,
        },
      }}
    >
      <FormProvider value={form} px="xl">
        <Stack spacing="md">
          <FormItem name={FIELDS.SCHEDULE}>
            <Switch label="Schedule" />
          </FormItem>
          {form.getFieldMeta(FIELDS.SCHEDULE).value && (
            <>
              <FormItem name={FIELDS.FREQUENCY}>
                <Select
                  label="Frequency"
                  options={Object.values(Frequency).map(frequency => ({
                    label: formatEnumValue(frequency),
                    value: frequency,
                  }))}
                  onChange={frequency => {
                    if (frequency === Frequency.DAILY) {
                      form.setFieldValue(FIELDS.DAY, EVERY_DAY_OPTION);
                    } else if (frequency === Frequency.MONTHLY) {
                      form.setFieldValue(FIELDS.DAY, ExportScheduleEnum.D01);
                    } else {
                      form.setFieldValue(FIELDS.DAY, ExportScheduleEnum.Monday);
                    }
                  }}
                />
              </FormItem>
              <FormItem name={FIELDS.DAY}>
                <Select
                  label="Day"
                  disabled={optionMeta.options.length === 1}
                  options={optionMeta.options.map(option => optionMeta.formatOption(option))}
                />
              </FormItem>
            </>
          )}
          {validateAccessScopes(TAccessOperation.READ, [UserWhoamiOutputScopesEnum.ExportsWithPii]) && (
            <FormItem name={FIELDS.INCLUDE_PII}>
              <Switch label="Include PII" />
            </FormItem>
          )}
        </Stack>
      </FormProvider>
    </Modal>
  );
};

export default ExportModal;
