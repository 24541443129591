import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { sentimentsCrossTenantApi } from "api/BudgeApi";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { InfiniteScrollTable, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import {
  SentimentPollSearchCriteriaInput,
  SentimentPollWithEmployeeOutput,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import SentimentDetails from "features/employees/tabs/Sentiments/SentimentDetails";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";
import { getSentimentPollColumns } from "./columns";
import ExportSentimentPollButton from "./components/ExportSentimentPollButton";
import { getSentimentPollFiltersConfig } from "./filters";

const filtersConfig = getSentimentPollFiltersConfig("employer-sentiment-polls-list-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const SentimentPollsListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      sentimentsCrossTenantApi
        .searchEmployeeSentimentPolls(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<SentimentPollSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedFilterContextValues}>
      <ListFiltersBar
        filtersConfig={filtersConfig}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsSentiments]}>
            <ExportSentimentPollButton />
          </ExportAccessScopeValidator>
        }
      />
      <InfiniteScrollTable<SentimentPollWithEmployeeOutput>
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        columns={getSentimentPollColumns()}
        local={{
          empty: {
            m: 24,
            title: "No sentiment polls found",
          },
          noMoreItems: "No more sentiment polls to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => <SentimentDetails sentiment={record} />}
      />
    </ListFiltersContext.Provider>
  );
};

export default SentimentPollsListTab;
