import { Collapse, FormProvider } from "@budgeinc/budge-ui-core";
import { PropsWithChildren, ReactNode } from "react";
import { StyleSheet, View } from "react-native";
import { useFormik } from "formik";

export type TCollapseEditForm<Entity> = PropsWithChildren<{
  header: ReactNode;
  initialOpen?: boolean;
  entity: Entity;
  onSubmit: (values: any) => Promise<any>;
  getFormValues: (employer: Entity) => any;
  validationSchema: any; // ObjectSchema<any, any, any>;
  actions?: React.ReactNode;
}>;

export type TEntityCollapseEditForm<Entity, UpdateEntity> = Omit<TCollapseEditForm<Entity>, "entity" | "onSubmit"> & {
  transformForSubmit: (values: any) => UpdateEntity;
  setEditMode: (value: boolean) => void;
};

const CollapseEditForm = <Entity,>({
  header,
  onSubmit,
  getFormValues,
  initialOpen,
  children,
  entity,
  validationSchema,
  actions,
}: TCollapseEditForm<Entity>) => {
  const form = useFormik({
    initialValues: getFormValues(entity),
    initialTouched: getFormValues(entity),
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <Collapse defaultOpen={initialOpen} trigger={header}>
      {actions && <View style={styles.actionsContainer}>{actions}</View>}
      <FormProvider value={form}>{children}</FormProvider>
    </Collapse>
  );
};

const styles = StyleSheet.create({
  actionsContainer: { alignSelf: "flex-start", marginBottom: 24 },
});

export default CollapseEditForm;
