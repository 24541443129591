import { accountsCrossTenantApi, employersApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import AccountsTable from "components/Table/AccountsTable";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { useEffect, useMemo, useReducer } from "react";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import { FinancialAccountSearchCriteriaInput, UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import ExportAccountsButton from "features/employers/entity/tabs/Financials/Accounts/components/ExportAccountsButton";
import { getAccountFiltersConfig } from "components/Table/AccountsTable/filters";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";

const filtersConfig = getAccountFiltersConfig("employee-accounts-list-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const AccountListTab = () => {
  const { state: pageState, employerId } = useEmployeeEntityContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const { requestState, onEndReached, forceRefresh, updateData } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      accountsCrossTenantApi
        .searchAccountsForEmployeesOfEmployer(
          employerId!,
          {
            ...filters,
            employeeId: pageState.employee?.id,
          },
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo<TListFilterContextType<FinancialAccountSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedContextValues}>
      <ListFiltersBar
        filtersConfig={filtersConfig}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.ClientsFinancials]}>
            <ExportAccountsButton
              extraFilters={{
                employeeId: pageState.employee?.id,
              }}
            />
          </ExportAccessScopeValidator>
        }
      />
      <AccountsTable
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        updateData={updateData}
      />
    </ListFiltersContext.Provider>
  );
};

export default AccountListTab;
