import {
  EmployeeInput,
  EmployeeOutput,
  ErrorResponse,
  UserOutputIdpSourceEnum,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import {
  BuildValidationSchema,
  DateSchema,
  EmailSchema,
  formatDateISO,
  parseDate,
  yup,
} from "@budgeinc/budge-ui-utils";
import {
  DatePickerInput,
  EditSaveToggleButtons,
  FormItem,
  FormProvider,
  getApiErrorMessage,
  Grid,
  Input,
  Stack,
  Switch,
  Tag,
  Text,
  toast,
  PhoneSchema,
  AccessScopeValidator,
  TAccessOperation,
  Collapse,
} from "@budgeinc/budge-ui-core";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { employeesCrossTenantApi } from "api/BudgeApi";
import { AxiosError } from "axios";
import { useAppMessages } from "store/global";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { isEmployeeMissingData } from "features/employers/entity/tabs/Employees/utils";
import ResetPasswordButton from "./components/ResetPasswordButton";
import UnlockUserButton from "./components/UnlockUserButton";
import HotjarRecordingsButton from "./components/HotjarRecordingsButton";

enum FIELDS {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  PHONE = "phone",
  EMAIL = "email",
  BIRTHDATE = "dob",
  LAST4 = "last4",
  PAYROLL_EMPLOYEE_ID = "payrollEmployeeId",
  SUBSCRIPTION_BYPASS = "isSubscriptionBypassed",
  MARKETING_EMAIL_OPTOUT = "hasOptedOutMarketingEmails",
  ONE_PAY_ENABLED = "onePayEnabled",
  SUBSCRIBED_PAYMENT_REMINDERS = "subscribedPaymentReminders",
}

type TFormValues = Record<FIELDS, any>;

const validationSchema = BuildValidationSchema({
  [FIELDS.FIRST_NAME]: yup.string().required(),
  [FIELDS.LAST_NAME]: yup.string().required(),
  [FIELDS.BIRTHDATE]: DateSchema(),
  [FIELDS.EMAIL]: EmailSchema().required(),
  [FIELDS.PHONE]: PhoneSchema().optional(),
  [FIELDS.LAST4]: yup.string().length(4),
  [FIELDS.SUBSCRIPTION_BYPASS]: yup.boolean(),
  [FIELDS.MARKETING_EMAIL_OPTOUT]: yup.boolean(),
  [FIELDS.SUBSCRIBED_PAYMENT_REMINDERS]: yup.boolean(),
});

const GeneralInfoCard = () => {
  const messages = useAppMessages();
  const isD2C = useIsEmployerD2C();
  const [editModeOn, setEditMode] = useState(false);
  const { state, dispatch, employerId } = useEmployeeEntityContext();
  const [formError, setFormError] = useState<string | undefined>();
  const [validSchema, setValidationSchema] = useState<any>(validationSchema);

  const activeUser = state.user!;
  const activeEmployee = state.employee!;

  const getLabelWithOptionalTag = (label: string, overridden: boolean | undefined) =>
    overridden && !isD2C ? `${label} (overridden)` : label;

  const form = useFormik({
    initialValues: getFormInitialValues(state.employee!),
    enableReinitialize: true,
    validationSchema: validSchema,
    onSubmit: async values => {
      try {
        return await employeesCrossTenantApi
          .updateEmployeeForEmployer(employerId!, activeEmployee.id, getEmployeePayload(values))
          .then(resp => {
            dispatch({
              type: "set",
              data: resp.data,
            });
            setEditMode(false);
            setValidationSchema(validationSchema);
            setFormError(undefined);
            toast.success({
              message: "Customer saved successfully",
            });
          })
          .catch((error: AxiosError<ErrorResponse>) => {
            setValidationSchema(validationSchema);
            setFormError(getApiErrorMessage(messages, error.response?.data));
          });
      } catch (e: any) {
        if (e.toString().includes("RangeError")) {
          form.setFieldError(FIELDS.BIRTHDATE, "Please enter a valid date");
        }
      }
    },
  });

  useEffect(() => {
    if (validSchema && formError) {
      // -- This is a hack to handle a uncommon for use case --
      // Submitting only if the validation schema is undefined
      manuallyTouchFields();
    }

    if (!validSchema) {
      form.handleSubmit();
    }
  }, [validSchema, formError]);

  const overriddenInfo = () =>
    activeEmployee.dobPayrollOverride ||
    activeEmployee.emailPayrollOverride ||
    activeEmployee.firstNamePayrollOverride ||
    activeEmployee.lastNamePayrollOverride ||
    activeEmployee.phonePayrollOverride;

  const manuallyTouchFields = () => {
    form.setTouched(
      {
        [FIELDS.FIRST_NAME]: true,
        [FIELDS.LAST_NAME]: true,
        [FIELDS.EMAIL]: true,
      },
      true
    );
  };

  useEffect(() => {
    if (isEmployeeMissingData(state.employee!)) {
      manuallyTouchFields();
    }
  }, [state.employee?.updatedAt]);

  return (
    <Collapse
      trigger={
        <Stack.Horizontal f={1} spacing="md" alignItems="center" justifyContent="space-between">
          <Stack.Horizontal>
            <Text fw="500" variant="bodyMedium">
              General Information
            </Text>
            {overriddenInfo() && !isD2C && <Tag value="Overridden" color="yellow" />}
          </Stack.Horizontal>
          <Stack.Horizontal>
            <HotjarRecordingsButton size="xs" userId={activeEmployee.userId!} />
            <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.Security]}>
              <UnlockUserButton size="xs" />
              {activeUser.idpSource === UserOutputIdpSourceEnum.Budge && <ResetPasswordButton size="xs" />}
            </AccessScopeValidator>
          </Stack.Horizontal>
        </Stack.Horizontal>
      }
      defaultOpen
    >
      <FormProvider value={form} formErrorMsg={formError}>
        <Stack spacing="md">
          <Grid column={3} gutter="md">
            <FormItem name={FIELDS.FIRST_NAME}>
              <Input
                label={getLabelWithOptionalTag("First Name", activeEmployee.firstNamePayrollOverride)}
                disabled={!editModeOn}
              />
            </FormItem>
            <FormItem name={FIELDS.LAST_NAME}>
              <Input
                label={getLabelWithOptionalTag("Last Name", activeEmployee.lastNamePayrollOverride)}
                disabled={!editModeOn}
              />
            </FormItem>
            <FormItem name={FIELDS.LAST4}>
              <Input label="SSN last 4 digits" disabled={!editModeOn} />
            </FormItem>
            <FormItem name={FIELDS.PHONE}>
              <Input
                label={getLabelWithOptionalTag("Phone", activeEmployee.phonePayrollOverride)}
                disabled={!editModeOn}
              />
            </FormItem>
            <FormItem name={FIELDS.EMAIL}>
              <Input
                label={getLabelWithOptionalTag("Email", activeEmployee.emailPayrollOverride)}
                disabled={!editModeOn}
              />
            </FormItem>
            <FormItem name={FIELDS.BIRTHDATE}>
              <DatePickerInput
                maxDate={new Date()}
                label={getLabelWithOptionalTag("Date of birth", activeEmployee.dobPayrollOverride)}
                disabled={!editModeOn}
              />
            </FormItem>
            {!isD2C && (
              <FormItem name={FIELDS.PAYROLL_EMPLOYEE_ID}>
                <Input label="Payroll Employee ID" disabled />
              </FormItem>
            )}
            <Input label="Status" value={activeEmployee.status} disabled />
          </Grid>
          <FormItem name={FIELDS.SUBSCRIBED_PAYMENT_REMINDERS}>
            <Switch label="Subscribed Payment Reminders" disabled={!editModeOn} />
          </FormItem>
          <FormItem name={FIELDS.SUBSCRIPTION_BYPASS}>
            <Switch label="Subscription Bypassed" disabled={!editModeOn} />
          </FormItem>
          <FormItem name={FIELDS.MARKETING_EMAIL_OPTOUT}>
            <Switch label="Marketing Emails Opt Out" disabled={!editModeOn} />
          </FormItem>
          <FormItem name={FIELDS.ONE_PAY_ENABLED}>
            <Switch label="OnePay Enabled" disabled={!editModeOn} />
          </FormItem>
          <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.Clients]}>
            <EditSaveToggleButtons
              isEditModeEnabled={editModeOn}
              setEditMode={setEditMode}
              onSubmit={() => {
                if (editModeOn) {
                  if (!validSchema) {
                    form.handleSubmit();
                  } else {
                    // -- This is a hack to handle a uncommon for use case --
                    // Removing schema validation to allow user
                    // to partially edit field even if some stay missing and required
                    setValidationSchema(undefined);
                  }
                } else {
                  setEditMode(true);
                }
              }}
              saveBtnDisabled={!form.dirty}
              onCancel={() => {
                form.setValues(form.initialValues, true);
                setFormError(undefined);
              }}
              loading={form.isSubmitting}
            />
          </AccessScopeValidator>
        </Stack>
      </FormProvider>
    </Collapse>
  );
};

const getFormInitialValues = (employee: EmployeeOutput): TFormValues => ({
  [FIELDS.FIRST_NAME]: employee.firstName || "",
  [FIELDS.LAST_NAME]: employee.lastName || "",
  [FIELDS.BIRTHDATE]: employee.dob ? parseDate(employee.dob) : null,
  [FIELDS.PHONE]: employee.phone || "",
  [FIELDS.EMAIL]: employee.email || "",
  [FIELDS.LAST4]: employee.last4 || "",
  [FIELDS.PAYROLL_EMPLOYEE_ID]: employee.payrollEmployeeId || "",
  [FIELDS.SUBSCRIPTION_BYPASS]: employee.isSubscriptionBypassed || false,
  [FIELDS.MARKETING_EMAIL_OPTOUT]: employee.hasOptedOutMarketingEmails || false,
  [FIELDS.ONE_PAY_ENABLED]: employee.hasOnePayEarlyAccess || false,
  [FIELDS.SUBSCRIBED_PAYMENT_REMINDERS]: !!employee.hasSubscribedPaymentReminders,
});

const getEmployeePayload = (values: TFormValues): EmployeeInput => ({
  firstName: values.firstName || undefined,
  lastName: values.lastName || undefined,
  email: values.email || undefined,
  dob: formatDateISO(values.dob, { withoutTime: true }),
  phone: values.phone || undefined,
  last4: values.last4 || undefined,
  isSubscriptionBypassed: values.isSubscriptionBypassed,
  hasOptedOutMarketingEmails: values.hasOptedOutMarketingEmails,
  hasOnePayEarlyAccess: values.onePayEnabled,
  hasSubscribedPaymentReminders: values.subscribedPaymentReminders,
});

export default GeneralInfoCard;
