import { EmployeeOutput, DebtPaymentPlanStatsOutput } from "@budgeinc/budge-api";
import { Maybe } from "@budgeinc/budge-ui-utils";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { debtPlanApi, employeesApi, subscriptionsApi } from "api/BudgeApi";

export const clients = createQueryKeys("clients", {
  subscription: (employee: EmployeeOutput | null) => ({
    queryKey: [employee?.id],
    queryFn: async () => {
      if (employee) {
        const response = await subscriptionsApi.getSubscription(employee.id);
        return response.data;
      }

      return null;
    },
  }),

  debtPlanStats: (employee: Maybe<EmployeeOutput>) => ({
    queryKey: [employee?.id, employee?.hasCompletedOnboarding, "debt-plan"],
    queryFn: async (): Promise<DebtPaymentPlanStatsOutput | null> => {
      if (employee?.id && employee.hasCompletedOnboarding) {
        const response = await debtPlanApi.getDebtPaymentPlanStatistics(employee.id);
        return response.data;
      }

      return null;
    },
  }),
});
