import React from "react";
import {
  ProgramTransactionAdminOutput,
  ProgramTransactionOutputStatusEnum,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import { formatMoney, i18n } from "@budgeinc/budge-ui-utils";
import {
  TColumnProps,
  Text,
  Tag,
  ReversedIcon,
  DeleteIcon,
  MoreDotsCircledIcon,
  SuccessIcon,
  Stack,
  EMPTY_VALUE_PLACEHOLDER,
  AccessScopeValidator,
  TAccessOperation,
} from "@budgeinc/budge-ui-core";
import { accountEntityRoute, employeeEntityRoute } from "common/routes";
import { Link } from "components/Link";
import MethodExternalButton from "components/Method/MethodExternalButton";
import DateDisplay from "components/DateDisplay";
import MethodRawDetailsButton from "components/Method/MethodRawDetailsButton";
import ApproveManualPaymentButton from "features/transactions/components/ManualPayment/ApproveManualPayment/ApproveManualPaymentButton";
import { MethodReadAccessScopeValidator } from "components/AccessScopeValidator/validators/MethodAccessScopeValidator";
import { formatEnumValue } from "utils/format";

export const getTransactionColumns = ({
  employerId,
  onTransactionUpdated,
  showEmployee = false,
}: {
  employerId: string;
  onTransactionUpdated: (transaction: ProgramTransactionAdminOutput) => void;
  showEmployee: boolean;
}): TColumnProps<ProgramTransactionAdminOutput>[] => {
  const columns: TColumnProps<ProgramTransactionAdminOutput>[] = [
    {
      id: "account",
      title: "Account",
      render: ({ programTransaction }) =>
        programTransaction.programEnrollment ? (
          <Text numberOfLines={2}>
            <Link
              to={accountEntityRoute(
                employerId,
                programTransaction.programEnrollment.financialAccount.employeeId,
                programTransaction.programEnrollment.financialAccount.id
              )}
            >
              {programTransaction.programEnrollment.financialAccount.name}
            </Link>
          </Text>
        ) : (
          <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
        ),
    },
    {
      id: "source",
      title: "Source",
      render: ({ programTransaction }) => (
        <Tag
          value={
            programTransaction.programEnrollment
              ? i18n.t(`enums.enrollmentSource.${programTransaction.programEnrollment.source}`, {
                  defaultValue: formatEnumValue(programTransaction.programEnrollment.source),
                })
              : "Budge Issued"
          }
        />
      ),
    },
    // readonly Submitted: "SUBMITTED";
    // readonly Hold: "HOLD";
    // readonly Processed: "PROCESSED";
    // readonly Failed: "FAILED";
    // readonly Reversed: "REVERSED";
    {
      id: "status",
      title: "Status",
      render: ({ programTransaction }) => {
        switch (programTransaction.status) {
          case ProgramTransactionOutputStatusEnum.Reversed:
            return <Tag value={programTransaction.status} icon={ReversedIcon} />;
          case ProgramTransactionOutputStatusEnum.Failed:
          case ProgramTransactionOutputStatusEnum.Rejected:
            return <Tag value={programTransaction.status} icon={DeleteIcon} color="red" />;
          case ProgramTransactionOutputStatusEnum.Submitted:
          case ProgramTransactionOutputStatusEnum.PendingApproval:
            return <Tag value={programTransaction.status} icon={MoreDotsCircledIcon} color="blue" />;
          case ProgramTransactionOutputStatusEnum.Processed:
          case ProgramTransactionOutputStatusEnum.Completed:
            return <Tag value={programTransaction.status} icon={SuccessIcon} color="green" />;
          case ProgramTransactionOutputStatusEnum.Hold:
            return <Tag value={programTransaction.status} icon={MoreDotsCircledIcon} color="yellow" />;
          default:
            return <Tag value={programTransaction.status} />;
        }
      },
    },
    {
      id: "amount",
      title: "Amount",
      render: ({ programTransaction }) => (
        <Text>
          {formatMoney({
            amountCents: programTransaction.transactionAmountCents,
          })}
        </Text>
      ),
    },
    {
      id: "transactiondate",
      title: "Transaction date",
      render: ({ programTransaction }) => (
        <DateDisplay numberOfLines={1} value={programTransaction.transactionDate} showTime />
      ),
    },
    {
      title: "",
      id: "action",
      align: "flex-end",
      render: record => (
        <Stack.Horizontal>
          {record.methodfiPaymentId && (
            <>
              <MethodExternalButton buttonType="actionIcon" recordId={record.methodfiPaymentId} type="payments" />
              <MethodReadAccessScopeValidator>
                <MethodRawDetailsButton recordId={record.methodfiPaymentId} type="paymentRaw" />
              </MethodReadAccessScopeValidator>
            </>
          )}
          <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.ManualPaymentsApproval]}>
            {record.programTransaction.status === ProgramTransactionOutputStatusEnum.PendingApproval && (
              <ApproveManualPaymentButton transaction={record} onSuccess={onTransactionUpdated} />
            )}
          </AccessScopeValidator>
        </Stack.Horizontal>
      ),
    },
  ];

  if (showEmployee) {
    columns.splice(1, 0, {
      id: "clientemployee",
      title: "Customer",
      render: ({ programTransaction, paymentRecipientName }) =>
        programTransaction.employee ? (
          <Text>
            <Link to={employeeEntityRoute(programTransaction.employee.employerId, programTransaction.employee.id)}>
              {paymentRecipientName}
            </Link>
          </Text>
        ) : paymentRecipientName ? (
          <Text>{paymentRecipientName}</Text>
        ) : (
          <Text>{EMPTY_VALUE_PLACEHOLDER}</Text>
        ),
    });
  }

  return columns;
};
