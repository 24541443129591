import { SubscriptionSearchCriteriaInputStatusEnum, SubscriptionSearchCriteriaInput } from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getSubscriptionsFiltersConfig = (persistId: string): TFiltersConfig<SubscriptionSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<SubscriptionSearchCriteriaInput>,
    {
      type: "string",
      apiAttribute: "stripeSubscriptionId",
      title: "Stripe",
      label: "Subscription ID",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "status",
      title: "Status",
      options: Object.values(SubscriptionSearchCriteriaInputStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
  ],
});
