import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { backofficeApi } from "api/BudgeApi";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import ReferralPaymentsTable from "components/Table/Referral/PaymentsList";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { getReferralPaymentFiltersConfig } from "components/Table/Referral/PaymentsList/filters";
import { initListFilters, ListFiltersContext } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";

const filtersConfig = getReferralPaymentFiltersConfig("employee-referral-payments-list-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const EarningsListTab = () => {
  const { state } = useEmployeeEntityContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      backofficeApi
        .searchReferralPayments(
          {
            ...filters,
            referrerEmployeeId: state.employee?.id,
          },
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedContextValues}>
      <ListFiltersBar filtersConfig={filtersConfig} />
      <ReferralPaymentsTable keyExtractor={item => item.id} requestState={requestState} onEndReached={onEndReached} />
    </ListFiltersContext.Provider>
  );
};

export default EarningsListTab;
