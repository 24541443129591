import { ExportRequestOutput, ExportRequestOutputOutputTypeEnum } from "@budgeinc/budge-api";
import { toast } from "@budgeinc/budge-ui-core";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { backofficeApi } from "api/BudgeApi";
import { download, generateDownloadFileName } from "utils/download";

export type TFetchExportRequestFileInput = {
  request: ExportRequestOutput;
  outputType: ExportRequestOutputOutputTypeEnum;
};

export const backoffice = createQueryKeys("backoffice", {
  fetchExportRequestFile: ({
    request,
    outputType = ExportRequestOutputOutputTypeEnum.Csv,
  }: TFetchExportRequestFileInput) => ({
    queryKey: [request.id, request.updatedAt],
    queryFn: async () => {
      const response = await backofficeApi.getExportRequestFile(request.id);

      toast.success({
        title: "Success",
        message: "File downloaded successfully",
      });

      download(
        response.data,
        generateDownloadFileName(request.type.toLowerCase(), outputType.toLowerCase()),
        outputType === ExportRequestOutputOutputTypeEnum.Csv ? "text/plain" : "application/zip"
      );

      return response.data;
    },
  }),
});
