import React from "react";
import { errorManager } from "@budgeinc/budge-ui-core";
import { backofficeApi } from "api/BudgeApi";
import ExportAsCsvButton from "components/Export/ExportAsCsvButton";
import { AxiosError } from "axios";
import { ErrorResponse } from "@budgeinc/budge-api";
import { showExportRequestToast } from "features/exports/utils";
import { useMutation } from "@tanstack/react-query";
import { TExportMutationOptions } from "components/Export/types";
import { useListFiltersContext } from "components/ListFilters/context";

const ExportReferralPaymentsButton = () => {
  const { state: filters } = useListFiltersContext();

  const searchMutation = useMutation({
    mutationFn: async (options: TExportMutationOptions) =>
      backofficeApi.searchReferralPaymentsAsCsv(filters, options.includePii, options.scheduling),
    onSuccess: response => showExportRequestToast(response.data),
    onError: (error: AxiosError<ErrorResponse>) => {
      errorManager.showError({
        error: error.response?.data,
      });
    },
  });

  return <ExportAsCsvButton loading={searchMutation.isPending} onPressExport={searchMutation.mutateAsync} />;
};

export default ExportReferralPaymentsButton;
