import React from "react";
import { errorManager } from "@budgeinc/budge-ui-core";
import { accountsCrossTenantApi } from "api/BudgeApi";
import ExportAsCsvButton from "components/Export/ExportAsCsvButton";
import { AxiosError } from "axios";
import { ErrorResponse, FinancialAccountDataRatingSearchCriteriaInput } from "@budgeinc/budge-api";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { useMutation } from "@tanstack/react-query";
import { showExportRequestToast } from "features/exports/utils";
import { TExportMutationOptions } from "components/Export/types";
import { useListFiltersContext } from "components/ListFilters/context";

interface OwnProps {
  extraFilters?: FinancialAccountDataRatingSearchCriteriaInput;
}

const ExportRatingButton = ({ extraFilters }: OwnProps) => {
  const {
    state: { employer },
  } = useEmployerContext();
  const { state: filters } = useListFiltersContext();

  const searchMutation = useMutation({
    mutationFn: async (options: TExportMutationOptions) =>
      accountsCrossTenantApi.searchAccountRatingsForEmployeesOfEmployerAsCsv(
        employer?.id!,
        {
          ...filters,
          ...extraFilters,
        },
        options.includePii,
        options.scheduling
      ),
    onSuccess: response => showExportRequestToast(response.data),
    onError: (error: AxiosError<ErrorResponse>) => {
      errorManager.showError({
        error: error.response?.data,
      });
    },
  });

  return <ExportAsCsvButton loading={searchMutation.isPending} onPressExport={searchMutation.mutateAsync} />;
};

export default ExportRatingButton;
