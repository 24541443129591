import React, { useCallback, useMemo, useReducer } from "react";
import { accountsCrossTenantApi } from "api/BudgeApi";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { Description, InfiniteScrollTableV2 } from "@budgeinc/budge-ui-core";
import { useBudgeInfiniteQuery } from "api/useBudgeInfiniteQuery";
import { getAccountDataRatingColumns } from "./columns";
import ExportRatingButton from "./components/ExportRatingButton";
import { getAccountDataRatingFiltersConfig } from "./filters";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters } from "components/ListFilters/utils";
import { FinancialAccountDataRatingSearchCriteriaInput } from "@budgeinc/budge-api";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";

const filtersConfig = getAccountDataRatingFiltersConfig("employer-accounts-ratings-list-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const DataRatingListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      accountsCrossTenantApi
        .searchAccountDataRatingsForEmployeesOfEmployer(
          employer?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => data),
    [JSON.stringify(filters)]
  );

  const { data, fetchNextPage, hasNextPage, isDataLoading, isFetchingNextPage } = useBudgeInfiniteQuery({
    queryKey: ["account-data-ratings", filters],
    queryFn: ({ pageParam }) => handleOnFetch({ page: pageParam, pageSize: 20 }),
    pageSize: 20,
    staleTime: 10 * 60 * 1000,
  });

  const accounts = useMemo(() => data?.pages.reduce((acc, page) => [...acc, ...page], []) || [], [data]);

  const memoedContextValues = useMemo<TListFilterContextType<FinancialAccountDataRatingSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedContextValues}>
      <ListFiltersBar filtersConfig={filtersConfig} extra={<ExportRatingButton />} />
      <InfiniteScrollTableV2
        data={accounts}
        columns={getAccountDataRatingColumns(employer?.id!)}
        keyExtractor={item => item.id}
        loading={isDataLoading}
        loadingNextPage={isFetchingNextPage}
        reachedEnd={!hasNextPage}
        onEndReached={() => fetchNextPage()}
        onEndReachedThreshold={0.5}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ marginBottom: 16 }}
        local={{
          empty: {
            title: "No ratings found",
          },
          noMoreItems: "No more ratings",
        }}
        expandable={record => (
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
          </Description>
        )}
      />
    </ListFiltersContext.Provider>
  );
};

export default DataRatingListTab;
