import {
  DebtPlanSearchCriteriaInput,
  DebtPlanSearchCriteriaInputDebtPaymentPlanStatusEnum,
  DebtPlanSearchCriteriaInputDebtPaymentPlanTypeEnum,
} from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getDebtPlanFiltersConfig = (persistId: string): TFiltersConfig<DebtPlanSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<DebtPlanSearchCriteriaInput>,
    {
      type: "checkboxgroup",
      apiAttribute: "debtPaymentPlanStatus",
      title: "Status",
      options: Object.values(DebtPlanSearchCriteriaInputDebtPaymentPlanStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
    {
      type: "checkboxgroup",
      apiAttribute: "debtPaymentPlanType",
      title: "Type",
      options: Object.values(DebtPlanSearchCriteriaInputDebtPaymentPlanTypeEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
  ],
});
