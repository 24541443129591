/* eslint-disable react/no-unstable-nested-components */
import { InviteOutput } from "@budgeinc/budge-api";
import { Description, InfiniteScrollTable } from "@budgeinc/budge-ui-core";

import { getInviteColumns } from "./columns";
import { TInfiniteScrollDataTableProps } from "../types";
import { useListFiltersContext } from "components/ListFilters/context";
import { hasFilters } from "components/ListFilters/utils";

type OwnProps = TInfiniteScrollDataTableProps<InviteOutput>;

const InvitesTable = ({ emptyLocal, ...props }: OwnProps) => {
  const { state: filters } = useListFiltersContext();

  return (
    <InfiniteScrollTable<InviteOutput>
      columns={getInviteColumns()}
      local={{
        empty: {
          m: 24,
          title: "No email verifications found",
          description: hasFilters(filters)
            ? "No email verifications match these filters. Edit or clear all filters."
            : undefined,
          ...emptyLocal,
        },
        noMoreItems: "No more email verifications to load",
      }}
      contentContainerStyle={{ marginBottom: 16 }}
      expandable={record => (
        <Description>
          <Description.Item label="ID">{record.id}</Description.Item>
        </Description>
      )}
      {...props}
    />
  );
};

export default InvitesTable;
