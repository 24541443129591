import {
  SubscriptionSearchCriteriaInput,
  SubscriptionWithEmployeeOutput,
  UserWhoamiOutputScopesEnum,
} from "@budgeinc/budge-api";
import { InfiniteScrollTable, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { useEmployerContext } from "features/employers/contexts/EmployerContext";
import { subscriptionsCrossTenantApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import ExportAccessScopeValidator from "components/AccessScopeValidator/validators/ExportAccessScopeValidator";
import SubscriptionDetails from "features/employees/tabs/Subscription/SubscriptionDetails";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters, hasFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";
import { getSubscriptionsColumns } from "./columns";
import ExportSubscriptionsButton from "./components/ExportSubscriptionsButton";
import { getSubscriptionsFiltersConfig } from "./filters";

const filtersConfig = getSubscriptionsFiltersConfig("employer-subscriptions-list-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const SubscriptionsListTab = () => {
  const {
    state: { employer },
  } = useEmployerContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      subscriptionsCrossTenantApi
        .searchEmployerSubscriptions(employer?.id!, filters, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({
          results: data,
        })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType<SubscriptionSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedFilterContextValues}>
      <ListFiltersBar
        filtersConfig={filtersConfig}
        extra={
          <ExportAccessScopeValidator rule={[UserWhoamiOutputScopesEnum.Subscriptions]}>
            <ExportSubscriptionsButton />
          </ExportAccessScopeValidator>
        }
      />
      <InfiniteScrollTable<SubscriptionWithEmployeeOutput>
        columns={getSubscriptionsColumns()}
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        local={{
          empty: {
            m: 24,
            title: "No subscriptions found",
            description: hasFilters(memoedFilterContextValues)
              ? `No subscription match these filters. Edit or clear all filters.`
              : undefined,
          },
          noMoreItems: "No more subscriptions to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => <SubscriptionDetails subscription={record} />}
      />
    </ListFiltersContext.Provider>
  );
};

export default SubscriptionsListTab;
