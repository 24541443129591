import { AuditLogSearchCriteriaInput } from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";

export const getAuditsFiltersConfig = (persistId: string): TFiltersConfig<AuditLogSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<AuditLogSearchCriteriaInput>,
    {
      type: "range",
      subType: "date",
      apiAttributeFrom: "dateFrom",
      apiAttributeTo: "dateTo",
      title: "Audit Date",
    },
  ],
});
