import { NotificationOutputTargetEnum } from "@budgeinc/budge-api";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { usersApi } from "api/BudgeApi";

export const user = createQueryKeys("user", {
  notifications: {
    queryKey: null,
    queryFn: async () => {
      const response = await usersApi.getNotifications({
        target: NotificationOutputTargetEnum.AdminApp,
      });
      return response.data;
    },
  },
});
