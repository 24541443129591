import { DirectPaymentSearchCriteriaInput, DirectPaymentSearchCriteriaInputStatusEnum } from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getDirectPaymentsFiltersConfig = (
  persistId: string
): TFiltersConfig<DirectPaymentSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<DirectPaymentSearchCriteriaInput>,
    {
      type: "range",
      subType: "date",
      apiAttributeFrom: "processedDateFrom",
      apiAttributeTo: "processedDateTo",
      title: "Processed Date",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "status",
      title: "Status",
      options: Object.values(DirectPaymentSearchCriteriaInputStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
  ],
});
