import React from "react";
import { Text, Collapse } from "@budgeinc/budge-ui-core";
import ProgramEnrollmentsTable from "components/Table/ProgramEnrollmentsTable";
import { programsApi } from "api/BudgeApi";
import { EmployeeOutput } from "@budgeinc/budge-api";
import { useBudgeQuery } from "api/useBudgeQuery";

interface OwnProps {
  employee: EmployeeOutput;
}

const ProgramEnrollmentsCard = ({ employee }: OwnProps) => {
  const { data, refetch, isPending } = useBudgeQuery({
    queryKey: [employee?.id, "program-enrollments"],
    queryFn: async () => (await programsApi.getProgramEnrollments(employee?.id!)).data,
  });

  return (
    <Collapse
      trigger={
        <Text fw="500" variant="bodyMedium">
          Program Enrollments
        </Text>
      }
      expandable={false}
      defaultOpen
    >
      <ProgramEnrollmentsTable
        keyExtractor={item => item.id}
        requestState={{
          data: (data || []).filter(({ debtPaymentPlanId }) => !!debtPaymentPlanId),
          page: 1,
          loading: isPending,
          moreLoading: false,
          refreshing: false,
          reachedEnd: false,
        }}
        onEndReached={() => {}}
        updateData={() => refetch()}
        pageSize={100}
        variant="dark"
      />
    </Collapse>
  );
};

export default ProgramEnrollmentsCard;
