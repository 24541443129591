import { ReferralPaymentSearchInputStatusEnum, ReferralPaymentSearchInput } from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getReferralPaymentFiltersConfig = (persistId: string): TFiltersConfig<ReferralPaymentSearchInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<ReferralPaymentSearchInput>,
    {
      type: "range",
      subType: "date",
      apiAttributeFrom: "paymentDateFrom",
      apiAttributeTo: "paymentDateTo",
      title: "Payment Date",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "status",
      title: "Status",
      options: Object.values(ReferralPaymentSearchInputStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
  ],
});
