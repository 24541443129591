import {
  ProgramEnrollmentSearchCriteriaInputStatusEnum,
  ProgramEnrollmentSearchCriteriaInput,
} from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getProgramEnrollmentFiltersConfig = (
  persistId: string
): TFiltersConfig<ProgramEnrollmentSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<ProgramEnrollmentSearchCriteriaInput>,
    {
      type: "checkboxgroup",
      apiAttribute: "status",
      title: "Status",
      options: Object.values(ProgramEnrollmentSearchCriteriaInputStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
  ],
});
