import { Text } from "@budgeinc/budge-ui-core";
import { Slot } from "@radix-ui/react-slot";
import { forwardRef, useContext } from "react";
import {
  LinkProps,
  UNSAFE_NavigationContext as NavigationContext,
  useHref,
  useLinkClickHandler,
} from "react-router-dom";

const ABSOLUTE_URL_REGEX = /^(?:[a-z][a-z0-9+.-]*:|\/\/)/i;

const isBrowser =
  typeof window !== "undefined" &&
  typeof window.document !== "undefined" &&
  typeof window.document.createElement !== "undefined";

export function stripBasename(pathname: string, basename: string): string | null {
  if (basename === "/") return pathname;

  if (!pathname.toLowerCase().startsWith(basename.toLowerCase())) {
    return null;
  }

  // We want to leave trailing slash behavior in the user's control, so if they
  // specify a basename with a trailing slash, we should support it
  const startIndex = basename.endsWith("/") ? basename.length - 1 : basename.length;
  const nextChar = pathname.charAt(startIndex);
  if (nextChar && nextChar !== "/") {
    // pathname does not start with basename/
    return null;
  }

  return pathname.slice(startIndex) || "/";
}

export const Link = forwardRef<
  Text,
  LinkProps & {
    asChild?: boolean;
  }
>(
  (
    {
      onClick,
      relative,
      reloadDocument,
      replace,
      state,
      target,
      to,
      rel,
      download,
      preventScrollReset,
      unstable_viewTransition,
      asChild,
      ...rest
    },
    ref
  ) => {
    const { basename } = useContext(NavigationContext);

    // Rendered into <a href> for absolute URLs
    let absoluteHref;
    let isExternal = false;

    if (typeof to === "string" && ABSOLUTE_URL_REGEX.test(to)) {
      // Render the absolute href server- and client-side
      absoluteHref = to;

      // Only check for external origins client-side
      if (isBrowser) {
        try {
          const currentUrl = new URL(window.location.href);
          const targetUrl = to.startsWith("//") ? new URL(currentUrl.protocol + to) : new URL(to);
          const path = stripBasename(targetUrl.pathname, basename);

          if (targetUrl.origin === currentUrl.origin && path != null) {
            // Strip the protocol/origin/basename for same-origin absolute URLs
            to = path + targetUrl.search + targetUrl.hash;
          } else {
            isExternal = true;
          }
        } catch (e) {
          // We can't do external URL detection without a valid URL
          // eslint-disable-next-line no-console
          console.warn(
            `<Link to="${to}"> contains an invalid URL which will probably break ` +
              `when clicked - please update to a valid URL path.`
          );
        }
      }
    }

    // Rendered into <a href> for relative URLs
    const href = useHref(to, { relative });

    const internalOnClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
      preventScrollReset,
      relative,
      unstable_viewTransition,
    });

    function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
      if (onClick) onClick(event);

      internalOnClick(event);
    }

    const Element = asChild ? Slot : Text;

    return (
      <Element
        ref={ref as any}
        // @ts-ignore
        role="link"
        href={absoluteHref || href}
        {...rest}
        // @ts-ignore
        // eslint-disable-next-line react/jsx-no-bind
        onPress={isExternal || reloadDocument ? onClick : handleClick}
      />
    );
  }
);
