import {
  formatEmployeeName,
  useInfiniteScrollList,
  TAccessOperation,
  useAccessScopeValidator,
} from "@budgeinc/budge-ui-core";
import React from "react";
import InvitesTable from "components/Table/InvitesTable";
import { View } from "react-native";
import { invitesCrossTenantApi } from "api/BudgeApi";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";

import ResendEmailVerificationsButton from "./components/ResendEmailVerificationsButton";

const EmailVerificationsListTab = () => {
  const {
    state: { employee },
    employerId,
  } = useEmployeeEntityContext();
  const { validateAccessScopes } = useAccessScopeValidator();
  const isAdminWrite = validateAccessScopes(TAccessOperation.WRITE);

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: ({ page, pageSize }) =>
      invitesCrossTenantApi
        .getEmployeeInvites(employerId!, employee?.id!, `${pageSize * (page - 1)}`, pageSize.toString())
        .then(({ data }) => ({ results: data })),
  });

  return (
    <>
      {isAdminWrite && !employee?.userId && requestState.data.length > 0 && (
        <View style={{ alignSelf: "flex-end", marginBottom: 24 }}>
          <ResendEmailVerificationsButton onInviteSent={forceRefresh} />
        </View>
      )}
      <InvitesTable
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        emptyLocal={
          !employee?.userId
            ? {
                description:
                  requestState.data.length === 0
                    ? `Send an email verifications to ${formatEmployeeName({
                        employee: employee!,
                      })}`
                    : "",
                action: isAdminWrite && requestState.data.length === 0 && (
                  <ResendEmailVerificationsButton onInviteSent={forceRefresh} />
                ),
              }
            : undefined
        }
      />
    </>
  );
};

export default EmailVerificationsListTab;
