import { Empty, SearchIcon, Text } from "@budgeinc/budge-ui-core";

interface OwnProps {
  title: string;
  description: string;
}

const EntityNotFound = ({ title, description }: OwnProps) => (
  <Empty
    icon={<SearchIcon size={75} color="gray.4" />}
    title={title}
    description={
      <Text maw={400} ta="center" display="flex">
        {description}
      </Text>
    }
    my={100}
  />
);

export default EntityNotFound;
