/* eslint-disable react/no-unstable-nested-components */
import { DirectPaymentOutput } from "@budgeinc/budge-api";
import { InfiniteScrollTable, Description, Divider } from "@budgeinc/budge-ui-core";
import DateDisplay from "components/DateDisplay";
import { useIsEmployerD2C } from "features/employers/contexts/EmployerContext";
import { useListFiltersContext } from "components/ListFilters/context";
import { hasFilters } from "components/ListFilters/utils";
import { getDirectPaymentsColumns } from "./columns";
import { TInfiniteScrollDataTableProps } from "../types";

type OwnProps = TInfiniteScrollDataTableProps<DirectPaymentOutput> & {
  showEmployee?: boolean;
  showLinkToEntity?: boolean;
};

const DirectPaymentsTable = ({ emptyLocal, showEmployee = false, showLinkToEntity = false, ...props }: OwnProps) => {
  const isD2c = useIsEmployerD2C();
  const { state: filters } = useListFiltersContext();

  return (
    <InfiniteScrollTable
      {...props}
      columns={getDirectPaymentsColumns({ isD2c, showEmployee })}
      local={{
        empty: {
          m: 24,
          title: "No direct payments found",
          description: hasFilters(filters)
            ? `No direct payment match these filters. Edit or clear all filters.`
            : undefined,
        },
        noMoreItems: "No more direct payments to load",
      }}
      contentContainerStyle={{ marginBottom: 40 }}
      expandable={record => (
        <Description>
          <Description.Item label="ID">{record.id}</Description.Item>
          <Divider />
          <Description.Item label="Updated At">
            <DateDisplay numberOfLines={1} value={record.updatedAt} showTime />
          </Description.Item>
          <Description.Item label="Created At">
            <DateDisplay numberOfLines={1} value={record.createdAt} showTime />
          </Description.Item>
        </Description>
      )}
    />
  );
};

export default DirectPaymentsTable;
