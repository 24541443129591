import { ReactNode } from "react";
import { NotificationOutputStatusEnum } from "@budgeinc/budge-api";
import { Stack, NotificationDrawerButton, Text, Box } from "@budgeinc/budge-ui-core";
import { useFetchNotifications, useUpdateNotification } from "api/hooks";

interface OwnProps {
  title?: ReactNode;
  subTitle?: string;
  extra?: ReactNode;
}

const ContentHeader = ({ title, subTitle, extra }: OwnProps) => {
  const notifications = useFetchNotifications();
  const updateNotification = useUpdateNotification();

  return (
    <Stack.Horizontal mb="xl" alignItems="center" justifyContent="space-between">
      <Stack.Horizontal f={1} alignItems="center" spacing="md">
        <Box f={1}>
          <Text numberOfLines={1} variant="titleH2">
            {title}
          </Text>
          {subTitle && <Text numberOfLines={1}>{subTitle}</Text>}
        </Box>
      </Stack.Horizontal>
      <Stack.Horizontal spacing="lg" alignItems="center">
        <Box>{extra}</Box>
        <NotificationDrawerButton
          notifications={notifications.data || []}
          onNotificationPress={({ id, status }, setDrawerVisible) => {
            if (status === NotificationOutputStatusEnum.Sent) {
              updateNotification.mutate({
                notificationId: id,
                updateInput: {
                  status: NotificationOutputStatusEnum.Consumed,
                },
              });
            }

            setDrawerVisible(false);
          }}
        />
      </Stack.Horizontal>
    </Stack.Horizontal>
  );
};

export default ContentHeader;
