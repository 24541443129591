import { EmployeeOutput } from "@budgeinc/budge-api";
import { queries } from "api/queries";
import { useBudgeQuery } from "api/useBudgeQuery";

export const useFetchSubscription = (employee: EmployeeOutput | null) =>
  useBudgeQuery({
    ...queries.clients.subscription(employee),
    staleTime: 5 * 60 * 1000,
  });

export const useFetchDebtPlanStats = (employee: EmployeeOutput | null) =>
  useBudgeQuery({
    ...queries.clients.debtPlanStats(employee),
    staleTime: 5 * 60 * 1000,
  });
