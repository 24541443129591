import React from "react";
import { ActionIcon, Button, SettingsIcon, Stack, Tooltip, useDisclosure } from "@budgeinc/budge-ui-core";
import { SearchEmployerCreditScoresAsCsvSchedulingEnum } from "@budgeinc/budge-api";
import { TButtonDropdownProps } from "../ButtonDropdown";
import { TExportMutationOptions } from "./types";
import ExportModal from "./ExportModal";

type OwnProps = Omit<TButtonDropdownProps, "onPress" | "dropdownOverlay"> & {
  onPressExport(options: TExportMutationOptions): Promise<any>;
};

const ExportAsCsvButton = ({
  onPressExport,
  size = "sm",
  variant = "gradient",
  color = "primary",
  ...props
}: OwnProps) => {
  const disclosure = useDisclosure();

  return (
    <>
      <Stack.Horizontal spacing={0}>
        <Button
          title="Export as CSV"
          color={color}
          variant={variant}
          sx={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
          pr="sm"
          size={size}
          {...props}
          onPress={() =>
            onPressExport({
              scheduling: SearchEmployerCreditScoresAsCsvSchedulingEnum.None,
              includePii: false,
            })
          }
        />
        <Tooltip content="Export Settings">
          <ActionIcon
            icon={SettingsIcon}
            size={size}
            color={color}
            variant={variant}
            iconSize="sm"
            onPress={disclosure.toggle}
            sx={{
              borderTopRightRadius: 999,
              borderBottomRightRadius: 999,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        </Tooltip>
      </Stack.Horizontal>
      <ExportModal isOpen={disclosure.isOpen} onClose={disclosure.close} onPressSchedule={onPressExport} />
    </>
  );
};

export default ExportAsCsvButton;
