import {
  FinancialAccountDataRatingSearchCriteriaInput,
  FinancialAccountDataRatingSearchCriteriaInputDataPointSourceEnum,
  FinancialAccountDataRatingSearchCriteriaInputDataPointRatingEnum,
  FinancialAccountDataRatingSearchCriteriaInputDataPointTypeEnum,
} from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getAccountDataRatingFiltersConfig = (
  persistId: string
): TFiltersConfig<FinancialAccountDataRatingSearchCriteriaInput> => ({
  persistId,
  showSearchBar: true,
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<FinancialAccountDataRatingSearchCriteriaInput>,
    {
      type: "checkboxgroup",
      apiAttribute: "dataPointSource",
      title: "Source",
      options: Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointSourceEnum).map(source => ({
        label: formatEnumValue(source),
        value: source,
      })),
    },
    {
      type: "checkboxgroup",
      apiAttribute: "dataPointRating",
      title: "Rating",
      options: Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointRatingEnum).map(rating => ({
        label: formatEnumValue(rating),
        value: rating,
      })),
    },
    {
      type: "checkboxgroup",
      apiAttribute: "dataPointType",
      title: "Type",
      options: Object.values(FinancialAccountDataRatingSearchCriteriaInputDataPointTypeEnum).map(type => ({
        label: formatEnumValue(type),
        value: type,
      })),
    },
  ],
});
