import React from "react";
import { initialState, TEmployeesAction, TEmployeesState } from "./reducer";

interface EmployeeSearchContextType {
  dispatch: React.Dispatch<TEmployeesAction>;
  state: TEmployeesState;
}

export const EmployeeSearchContext = React.createContext<EmployeeSearchContextType>({
  dispatch: () => {},
  state: initialState,
});
