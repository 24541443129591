import React from "react";
import { TIntlFormatDateParams, TTextProps, Text, Tooltip, intlFormatDate } from "@budgeinc/budge-ui-core";

interface OwnProps extends TTextProps, Pick<TIntlFormatDateParams, "month" | "day" | "year" | "showTime"> {
  value: string;
}

const DateDisplay = ({ value, showTime, month = "short", day = "numeric", year = "numeric", ...props }: OwnProps) => (
  <Tooltip
    content={`UTC: ${intlFormatDate({
      value,
      showTime,
      month,
      day,
      year,
    })}`}
  >
    <Text {...props}>
      {intlFormatDate({
        value,
        showTime,
        useUserTimezone: true,
        month,
        day,
        year,
      })}
    </Text>
  </Tooltip>
);

export default DateDisplay;
