import React, { useContext } from "react";
import { createListFilterReducer, TListFilterAction, TListFilterState } from "./reducer";
import { EmployeeSearchCriteriaInput } from "@budgeinc/budge-api";
import { TFiltersConfig } from "./types";

export interface TListFilterContextType<T = any> {
  dispatch: React.Dispatch<TListFilterAction<T>>;
  state: TListFilterState<T>;
}

export function createListFilterContext<T>(initialState: TListFilterState<T>) {
  return React.createContext<TListFilterContextType<T>>({
    dispatch: () => {},
    state: initialState,
  });
}

export const ListFiltersContext = createListFilterContext<any>({});

export const initListFilters = (filtersConfig: TFiltersConfig<any>) => {
  const initialFiltersState: TListFilterState<any> = {};

  filtersConfig.filters.reduce((prev, current) => {
    if (current.type === "range") {
      initialFiltersState[current.apiAttributeFrom] = undefined;
      initialFiltersState[current.apiAttributeTo] = undefined;
    } else {
      initialFiltersState[current.apiAttribute] = undefined;
    }
  }, initialFiltersState);

  return {
    ListFiltersReducer: createListFilterReducer<any>(initialFiltersState),
  };
};

export const useListFiltersContext = () => {
  const context = useContext(ListFiltersContext);

  if (!context) {
    throw new Error("ListFiltersContext must be used inside an ListFiltersContext.Provider");
  }

  return context;
};
