import { FinancialAccountTransferOutput, FinancialAccountTransferSearchCriteriaInput } from "@budgeinc/budge-api";
import { Description, InfiniteScrollTable, Text, useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { accountsCrossTenantApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { Link } from "react-router-dom";
import { accountEntityRoute } from "common/routes";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getInitialFilters, hasFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";
import { getTransfersFiltersConfig } from "./filters";
import { getTransfersColumns } from "./columns";

const filtersConfig = getTransfersFiltersConfig("employee-transfers-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const TransfersListTab = () => {
  const { state: pageState, employerId } = useEmployeeEntityContext();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      accountsCrossTenantApi
        .searchEmployeeAccountTransfers(
          employerId!,
          pageState.employee?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({
          results: data,
        })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedContextValues = useMemo<TListFilterContextType<FinancialAccountTransferSearchCriteriaInput>>(
    () => ({
      state: filters,
      dispatch,
    }),
    [filters, dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedContextValues}>
      <ListFiltersBar filtersConfig={filtersConfig} />
      <InfiniteScrollTable<FinancialAccountTransferOutput>
        columns={getTransfersColumns()}
        keyExtractor={item => item.id}
        requestState={requestState}
        onEndReached={onEndReached}
        local={{
          empty: {
            m: 24,
            title: "No account transfers found",
            description: hasFilters(memoedContextValues)
              ? `No transfer match these filters. Edit or clear all filters.`
              : undefined,
          },
          noMoreItems: "No more account transfers to load",
        }}
        contentContainerStyle={{ marginBottom: 16 }}
        expandable={record => (
          <Description>
            <Description.Item label="ID">{record.id}</Description.Item>
            <Description.Item label="Account ID (From)">
              <Text>
                <Link to={accountEntityRoute(employerId!, pageState.employee?.id!, record.financialAccountFromId)}>
                  {record.financialAccountFromId}
                </Link>
              </Text>
            </Description.Item>
            <Description.Item label="Account ID (To)">
              <Text>
                <Link to={accountEntityRoute(employerId!, pageState.employee?.id!, record.financialAccountToId)}>
                  {record.financialAccountToId}
                </Link>
              </Text>
            </Description.Item>
          </Description>
        )}
      />
    </ListFiltersContext.Provider>
  );
};

export default TransfersListTab;
