import React from "react";
import { Select } from "@budgeinc/budge-ui-core";
import { useMutation } from "@tanstack/react-query";
import { accountsApi } from "api/BudgeApi";
import {
  FinancialAccountOutput,
  FinancialAccountUpdateInputMinimumPaymentIngestionBehaviorEnum,
} from "@budgeinc/budge-api";
import { formatEnumValue } from "utils/format";

const MinimumPaymentIngestionBehaviorSelect = ({
  account,
  onAccountChange,
}: {
  account: FinancialAccountOutput;
  onAccountChange(account: FinancialAccountOutput): void;
}) => {
  const mutation = useMutation({
    mutationFn: async (value: FinancialAccountUpdateInputMinimumPaymentIngestionBehaviorEnum) => {
      const response = accountsApi.updateEmployeeFinancialAccount(account.employeeId, account.id, {
        minimumPaymentIngestionBehavior: value,
      });
      return (await response).data;
    },
    onSuccess: updatedAccount => onAccountChange(updatedAccount),
  });

  return (
    <Select
      label="Minimum Payment Ingestion Behavior"
      value={account.minimumPaymentIngestionBehavior}
      options={Object.values(FinancialAccountUpdateInputMinimumPaymentIngestionBehaviorEnum).map(value => ({
        label: formatEnumValue(value),
        value,
      }))}
      onChange={value => mutation.mutate(value as FinancialAccountUpdateInputMinimumPaymentIngestionBehaviorEnum)}
    />
  );
};

export default MinimumPaymentIngestionBehaviorSelect;
