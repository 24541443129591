import { ErrorResponse } from "@budgeinc/budge-api";
import { getApiErrorMessage } from "@budgeinc/budge-ui-core";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { globalSelector } from "store/global/selector";

export const useBudgeQuery = <
  TQueryFnData = unknown,
  TError = AxiosError<ErrorResponse>,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
) => {
  const { messages } = useSelector(globalSelector);
  const useQueryResult = useQuery<TQueryFnData, TError, TData, TQueryKey>(options);

  const errorMessage = useMemo(() => {
    if (useQueryResult.error == null) return "";

    const error = useQueryResult.error as any as AxiosError;

    return error.response?.data ? getApiErrorMessage(messages, useQueryResult.error as any) : error.message;
  }, [useQueryResult.error]);

  return {
    ...useQueryResult,
    errorMessage,
  };
};
