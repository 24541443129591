import {
  ProgramTransactionSearchCriteriaInputStatusEnum,
  ProgramTransactionSearchCriteriaInput,
} from "@budgeinc/budge-api";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";
import { formatEnumValue } from "utils/format";

export const getTransactionsFiltersConfig = (
  persistId: string,
  options?: {
    showSearchBar?: boolean;
  }
): TFiltersConfig<ProgramTransactionSearchCriteriaInput> => ({
  persistId,
  showSearchBar: options?.showSearchBar === undefined ? true : (options.showSearchBar as any),
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<ProgramTransactionSearchCriteriaInput>,
    {
      type: "range",
      subType: "date",
      apiAttributeFrom: "transactionDateFrom",
      apiAttributeTo: "transactionDateTo",
      title: "Transaction Date",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "status",
      title: "Status",
      options: Object.values(ProgramTransactionSearchCriteriaInputStatusEnum).map(status => ({
        label: formatEnumValue(status),
        value: status,
      })),
    },
  ],
});
