import { LoginHistoryOutputTargetEnum, LoginSearchCriteriaInput } from "@budgeinc/budge-api";
import { i18n } from "@budgeinc/budge-ui-core";
import { customerCreationDateRangeFilter } from "components/ListFilters/filters";
import { TFilter, TFiltersConfig } from "components/ListFilters/types";

export const getLoginsFiltersConfig = (
  persistId: string,
  options?: {
    showSearchBar?: boolean;
  }
): TFiltersConfig<LoginSearchCriteriaInput> => ({
  persistId,
  showSearchBar: options?.showSearchBar === undefined ? true : (options.showSearchBar as any),
  searchBarPlaceholder: "Search by first name, last name or email",
  filters: [
    customerCreationDateRangeFilter as TFilter<LoginSearchCriteriaInput>,
    {
      type: "range",
      subType: "date",
      apiAttributeFrom: "loginDateFrom",
      apiAttributeTo: "loginDateTo",
      title: "Login Date",
    },
    {
      type: "checkboxgroup",
      apiAttribute: "target",
      title: "Status",
      options: Object.values(LoginHistoryOutputTargetEnum).map(target => ({
        label: i18n.get(`enums.loginHistoryTargets.${target}`),
        value: target,
      })),
    },
    {
      type: "boolean",
      apiAttribute: "success",
      title: "Success",
    },
    {
      type: "string",
      apiAttribute: "ipAddress",
      title: "IP Addresses",
      label: "IP Addresses (separated by comma)",
    },
  ],
});
