import React, { PropsWithChildren } from "react";
import { UserWhoamiOutputScopesEnum } from "@budgeinc/budge-api";
import { TAccessOperation, AccessScopeValidator } from "@budgeinc/budge-ui-core";

export const MethodAccessScopeValidator = ({ children }: PropsWithChildren) => (
  <AccessScopeValidator op={TAccessOperation.WRITE} rule={[UserWhoamiOutputScopesEnum.AdvancedMethod]}>
    {children}
  </AccessScopeValidator>
);

export const MethodReadAccessScopeValidator = ({ children }: PropsWithChildren) => (
  <AccessScopeValidator op={TAccessOperation.READ} rule={[UserWhoamiOutputScopesEnum.AdvancedMethod]}>
    {children}
  </AccessScopeValidator>
);
