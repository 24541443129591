import { directPaymentsCrossTenantApi } from "api/BudgeApi";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useInfiniteScrollList } from "@budgeinc/budge-ui-core";
import { useEmployeeEntityContext } from "features/employees/contexts/EmployeeEntityContext";
import DirectPaymentsTable from "components/Table/DirectPaymentsTable";
import { useSearchParams } from "react-router-dom";
import { initListFilters, ListFiltersContext, TListFilterContextType } from "components/ListFilters/context";
import { getDirectPaymentsFiltersConfig } from "components/Table/DirectPaymentsTable/filters";
import { getInitialFilters } from "components/ListFilters/utils";
import ListFiltersBar from "components/ListFilters/ListFiltersBar";

const filtersConfig = getDirectPaymentsFiltersConfig("employee-direct-payments-filters");
const { ListFiltersReducer } = initListFilters(filtersConfig);

const EmployeeDirectPaymentsListTab = () => {
  const {
    state: { employee },
    employerId,
  } = useEmployeeEntityContext();
  const [params] = useSearchParams();
  const [filters, dispatch] = useReducer(ListFiltersReducer, getInitialFilters(filtersConfig));

  const handleOnFetch = useCallback(
    ({ page, pageSize }: { page: number; pageSize: number }) =>
      directPaymentsCrossTenantApi
        .searchEmployeeDirectPayments(
          employerId!,
          employee?.id!,
          filters,
          `${pageSize * (page - 1)}`,
          pageSize.toString()
        )
        .then(({ data }) => ({ results: data })),
    [JSON.stringify(filters)]
  );

  const { requestState, onEndReached, forceRefresh } = useInfiniteScrollList({
    onFetch: handleOnFetch,
    skipFirstForceRefresh: true,
  });

  useEffect(() => {
    forceRefresh();
  }, [JSON.stringify(filters)]);

  const memoedFilterContextValues = useMemo<TListFilterContextType>(
    () => ({
      state: filters,
      dispatch,
    }),
    [JSON.stringify(filters), dispatch]
  );

  return (
    <ListFiltersContext.Provider value={memoedFilterContextValues}>
      <ListFiltersBar filtersConfig={filtersConfig} />
      <DirectPaymentsTable keyExtractor={item => item.id} onEndReached={onEndReached} requestState={requestState} />
    </ListFiltersContext.Provider>
  );
};

export default EmployeeDirectPaymentsListTab;
